import { Base64 } from 'js-base64'
import { captureError } from '~/lib/logger'
import { getConfigCatUserObject, trackExperiment, trackStartup } from '~/lib/experiments/utils'
import { COOKIES } from '~/constants'
import type { Experiments, ExperimentsMapped, ExperimentVariant, Nullable } from '~/types'

export default defineNuxtPlugin((nuxtApp) => {
  const experiments = useExperiments()
  const runtimeConfig = useRuntimeConfig()
  const { isWebView } = usePlatform()
  const sessionId = useCookie(COOKIES.session)
  const { geolocation } = useGeolocation()
  const device = useDevice()
  const { user } = useAuthentication()
  const cookie = useCookie(COOKIES.experimentOverrides, {
    domain: runtimeConfig.public.cookieDomain,
    path: '/',
  })

  if (import.meta.server) {
    experiments.value = nuxtApp.$evaluatedExperiments as Experiments
  }

  watch(cookie, async () => {
    await refreshExperiments()
  })

  function get(experimentKey: string) {
    return experiments.value?.evaluated[experimentKey]
  }

  function getVariant(experimentKey: string) {
    return get(experimentKey)?.variant
  }

  function getAll() {
    return experiments.value?.evaluated
  }

  function getAllVariants(): ExperimentsMapped {
    const variants: {
      [key: string]: ExperimentVariant
    } = {}

    for (const key in experiments.value?.evaluated) {
      variants[key] = experiments.value.evaluated[key].variant
    }
    return variants
  }

  function getOverrides() {
    return experiments.value?.overridden
  }

  function getOverride(experimentKey: string, defaultValue: boolean | number | string) {
    return get(experimentKey)?.overrider ?? defaultValue
  }

  function isEnabled(experimentKey: string) {
    if (!experimentKey) return false

    const experiment = get(experimentKey)
    if (!experiment) return false

    return experiment.variant !== experiment.defaultVariant
  }

  function trackView({ experimentKey, startup = false }: { experimentKey: string, startup?: boolean }) {
    const experiment = get(experimentKey)
    trackExperiment(experiment, { startup, isWebview: isWebView.value })
  }

  function trackStartupExperiments() {
    trackStartup(getAll(), isWebView.value)
  }

  async function updateOverride(experimentKey: string, value: Nullable<ExperimentVariant>) {
    const decodedCookie = decodeCookie()

    if (value === null) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete decodedCookie[experimentKey]
      cookie.value = encodeCookie(decodedCookie)
    }
    else {
      cookie.value = encodeCookie({
        ...decodedCookie,
        [experimentKey]: value,
      })
    }
  }

  function getFlag(flag: string) {
    const decodedCookie = decodeCookie()
    return decodedCookie[`*${flag}`]
  }

  function decodeCookie() {
    return JSON.parse(Base64.decode(cookie.value || '') || '{}')
  }

  function encodeCookie(value: Record<string, unknown>) {
    return Base64.encode(JSON.stringify(value))
  }

  function setFlag(flag: string, value: boolean | number | string) {
    const decodedCookie = decodeCookie()
    cookie.value = encodeCookie({
      ...decodedCookie,
      [`*${flag}`]: value,
    })
  }

  function login() {
    setFlag('authenticated', true)
  }

  function logout() {
    setFlag('authenticated', false)
  }

  function isAuthenticated() {
    return getFlag('authenticated')
  }

  function setPermanent(value?: boolean | number | string) {
    if (!value) {
      value = false
    }

    setFlag('permanent', value)

    // Recreate the cookie with the new maxAge.
    useCookie(COOKIES.experimentOverrides, {
      domain: runtimeConfig.public.cookieDomain,
      path: '/',
      maxAge: value ? nuxtApp.$config.public.sessionCookieTTL : undefined,
    }).value = cookie.value
  }

  function getPermanent() {
    return getFlag('permanent')
  }

  async function refreshExperiments() {
    if (import.meta.dev) {
      console.log('Refreshing experiments')
    }

    const utils = {
      user: {
        Id: user.value?.Id,
        Email: user.value?.Email,
        Language: user.value?.Language,
        IsOwner: user.value?.IsOwner,
      },
      session: sessionId.value,
      ccUser: getConfigCatUserObject({
        user: user.value,
        config: runtimeConfig,
        $device: device,
        sessionId: sessionId.value,
        $geolocation: geolocation.value,
      }),
    }

    try {
      const response = await $fetch('/api/experiments', {
        method: 'POST',
        cache: 'no-cache',
        credentials: 'include',
        body: utils,
      })

      if (response) {
        experiments.value = response
      }
    }
    catch (error) {
      captureError(error)
    }
  }

  return {
    provide: {
      experiment: {
        trackView,
        trackStartupExperiments,
        getVariant,
        isEnabled,
        get,
        getAll,
        refreshExperiments,
        updateOverride,
        getOverrides,
        getOverride,
        login,
        logout,
        isAuthenticated,
        setPermanent,
        getPermanent,
        getAllVariants,
      },
    },
  }
})
