import type { Directive } from 'vue'
import { trackExternalLinkClicked } from '~/lib/tracking'

type TrackExternalLinkParams = {
  cta: string
} | undefined

export default defineNuxtPlugin((nuxtApp) => {
  const { routeBaseName } = useBaseName()
  const { isOwner } = useUser()

  function clickHandler({
    event,
    anchor,
    params,
  }: {
    event: Event
    anchor: HTMLAnchorElement
    params: TrackExternalLinkParams
  }) {
    event.preventDefault()

    trackExternalLinkClicked({
      externalLink: anchor.href,
      cta: params?.cta ?? '',
      pageSource: routeBaseName.value,
      isOwner: isOwner.value,
    })

    window.open(anchor.href, anchor.target)
  }

  /**
   * This directive is to replace ExternalLink component
   */
  nuxtApp.vueApp.directive('track-external-link', <Directive<HTMLAnchorElement, TrackExternalLinkParams>>{
    mounted(el, binding) {
      el.addEventListener('click', (e: Event) => clickHandler({
        event: e,
        anchor: el,
        params: binding.value,
      }))
    },

    unmounted(el, binding) {
      el.removeEventListener('click', (e: Event) => clickHandler({
        event: e,
        anchor: el,
        params: binding.value,
      }))
    },

    getSSRProps() {
      return {}
    },
  })
})
