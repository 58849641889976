import validate from "/azp/_work/1/s/node_modules/nuxt/dist/pages/runtime/validate.js";
import clarity_45identify_45global from "/azp/_work/1/s/src/middleware/clarity-Identify.global.ts";
import layout_45params_45global from "/azp/_work/1/s/src/middleware/layout-params.global.ts";
import maintenance_45mode_45global from "/azp/_work/1/s/src/middleware/maintenanceMode.global.ts";
import modals_45queue_45purge_45global from "/azp/_work/1/s/src/middleware/modals-queue-purge.global.ts";
import manifest_45route_45rule from "/azp/_work/1/s/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  clarity_45identify_45global,
  layout_45params_45global,
  maintenance_45mode_45global,
  modals_45queue_45purge_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  authentication: () => import("/azp/_work/1/s/src/middleware/authentication.ts"),
  estimate: () => import("/azp/_work/1/s/src/middleware/estimate.ts"),
  keyword: () => import("/azp/_work/1/s/src/middleware/keyword.ts"),
  "minimal-layout": () => import("/azp/_work/1/s/src/middleware/minimal-layout.ts"),
  "sidebase-auth": () => import("/azp/_work/1/s/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/sidebase-auth.js")
}