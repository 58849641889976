/**
 * This composable returns helpers based on the current user.
 * TODO: namespace this by prefixing all returned properties with `user`?
 */
export default function useUser() {
  const { user } = useAuthentication()
  const permissions = usePermissions()

  // Flags
  const isOwner = computed(() => Boolean(user.value?.IsOwner))
  const isSuperhost = computed(() => Boolean(user.value?.SuperHost?.IsActive))
  const isAdmin = computed(() => permissions.value?.includes('ADMINISTRATOR'))
  const isVerified = computed(() => Boolean(user.value?.VerificationStatus === 'Verified'))
  const isCanadian = computed(() => user.value?.Country === 'CA')

  const hasAvailableCredit = computed(() => user.value?.TotalAvailableCredit > 0)

  // Helpers
  const initials = computed(() => {
    if (!user.value) {
      return
    }

    const { FirstName = '', LastName = '' } = { ...user.value }
    return formatNameAsInitials(FirstName, LastName)
  })

  return {
    isOwner,
    isSuperhost,
    isAdmin,
    isVerified,
    isCanadian,

    hasAvailableCredit,

    initials,
  }
}
