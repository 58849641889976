
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as lp_45signupWn0zsVpa1BMeta } from "/azp/_work/1/s/src/pages/(marketing)/lp-signup.vue?macro=true";
import { default as aboutCLsIsivpblMeta } from "/azp/_work/1/s/src/pages/about.vue?macro=true";
import { default as indexAk2v4xDpY0Meta } from "/azp/_work/1/s/src/pages/affiliates/index.vue?macro=true";
import { default as app_45downloadwR8jLjLQ46Meta } from "/azp/_work/1/s/src/pages/app-download.vue?macro=true";
import { default as _91slug_93KT3QShQ3eNMeta } from "/azp/_work/1/s/src/pages/author/[slug].vue?macro=true";
import { default as _91slug_933RPHl8zYeoMeta } from "/azp/_work/1/s/src/pages/blog/[slug].vue?macro=true";
import { default as indexVMSjv8lTA5Meta } from "/azp/_work/1/s/src/pages/blog/index.vue?macro=true";
import { default as callback_45oauthFX1VjH5PFRMeta } from "/azp/_work/1/s/src/pages/callback-oauth.vue?macro=true";
import { default as careers_45data_45analyst68FgaMXrXUMeta } from "/azp/_work/1/s/src/pages/careers-data-analyst.vue?macro=true";
import { default as careers_45owner_45successcnMrSOaVHBMeta } from "/azp/_work/1/s/src/pages/careers-owner-success.vue?macro=true";
import { default as careers_45trust_45and_45safetypBE95pMbHBMeta } from "/azp/_work/1/s/src/pages/careers-trust-and-safety.vue?macro=true";
import { default as careersOlwtcYzniIMeta } from "/azp/_work/1/s/src/pages/careers.vue?macro=true";
import { default as _91slug_93u9YqPER3SCMeta } from "/azp/_work/1/s/src/pages/category/[slug].vue?macro=true";
import { default as errorhEDNV5mMKBMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/agreement/error.vue?macro=true";
import { default as indexI8GTcVnyFCMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/agreement/index.vue?macro=true";
import { default as signature7zFOEeeIVLMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/agreement/signature.vue?macro=true";
import { default as successm097zgwohXMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/agreement/success.vue?macro=true";
import { default as agreementOJG6XG7AbVMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/agreement.vue?macro=true";
import { default as acceptzLVk3MQTqHMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/change-request/accept.vue?macro=true";
import { default as declineVqMN9ZjmAJMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/change-request/decline.vue?macro=true";
import { default as indexTxIwnwja5vMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/change-request/index.vue?macro=true";
import { default as review3j93erhSYGMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/change-request/review.vue?macro=true";
import { default as change_45requestN2GGi9yVslMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/change-request.vue?macro=true";
import { default as custom_45quoteTtQH5jQi7cMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/custom-quote.vue?macro=true";
import { default as acceptGhpSec0OLzMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/accept.vue?macro=true";
import { default as acceptedpajH9IBQYEMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/accepted.vue?macro=true";
import { default as cancelf9tQT9pa4wMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/cancel.vue?macro=true";
import { default as closeoutFVbPPtIMsxMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/closeout.vue?macro=true";
import { default as completeheHuPAcfvuMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/complete.vue?macro=true";
import { default as declinekSe8S7IzJrMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/decline.vue?macro=true";
import { default as documentsiVV8X2CxbYMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/documents.vue?macro=true";
import { default as addonsNvpdBYxuDjMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/edit/addons.vue?macro=true";
import { default as datesELhFQuvKTcMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/edit/dates.vue?macro=true";
import { default as destinationpMZifEvpF6Meta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/edit/destination.vue?macro=true";
import { default as detailsklgrWrZ9faMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/edit/details.vue?macro=true";
import { default as indexbkVz0cZTNYMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/edit/index.vue?macro=true";
import { default as pendingZ7eO1rk151Meta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/edit/pending.vue?macro=true";
import { default as reviewTOGyYRz7pcMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/edit/review.vue?macro=true";
import { default as editQ4NvAouGfdMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/edit.vue?macro=true";
import { default as full_45review8tEzTZX2HqMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/full-review.vue?macro=true";
import { default as income_45protectionVWJtkWsNJHMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/income-protection.vue?macro=true";
import { default as index3diB7j4cJuMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/index.vue?macro=true";
import { default as insurance7NINEqS4TYMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/insurance.vue?macro=true";
import { default as indexueu9EO6uwXMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index.vue?macro=true";
import { default as insurance_45efnol7NfsxsrzCQMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/insurance-efnol.vue?macro=true";
import { default as invoicezlh21teNygMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/invoice.vue?macro=true";
import { default as paymentMVdsGZBEp2Meta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/payment.vue?macro=true";
import { default as retry_45paymentpQqRlfCYgqMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/retry-payment.vue?macro=true";
import { default as reviewM34FOT85nlMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/review.vue?macro=true";
import { default as disputeodnObwFaVkMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/dispute.vue?macro=true";
import { default as indexejG2mrHQH0Meta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/index.vue?macro=true";
import { default as requestFRSFzUqfFEMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/request.vue?macro=true";
import { default as reviewS232qxcB2wMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/review.vue?macro=true";
import { default as sendjIKEpSkzvOMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/send.vue?macro=true";
import { default as sentoghD6Y1sG4Meta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/sent.vue?macro=true";
import { default as successhLuXNxaAPYMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/success.vue?macro=true";
import { default as send_45request_45moneyOfvoMOOThlMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/send-request-money.vue?macro=true";
import { default as completeTBF46XoZhuMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/services/update/complete.vue?macro=true";
import { default as index0FihH8kpQGMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/services/update/index.vue?macro=true";
import { default as reviewkISOmtkPgUMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/services/update/review.vue?macro=true";
import { default as updatek0Nn08AfBHMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/services/update.vue?macro=true";
import { default as update_45payment5qC5N0BtZhMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/[id]/update-payment.vue?macro=true";
import { default as completedxzPuoSSXBwMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/index/completed.vue?macro=true";
import { default as confirmedLOP4vXpkjtMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/index/confirmed.vue?macro=true";
import { default as ezymatchotdLBtIJVvMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/index/ezymatch.vue?macro=true";
import { default as index7o4SrrexMwMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/index/index.vue?macro=true";
import { default as pendingmnaNEfCxwpMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/index/pending.vue?macro=true";
import { default as indexMP4Q01KBUlMeta } from "/azp/_work/1/s/src/pages/dashboard/bookings/index.vue?macro=true";
import { default as calendarJFkmdrHfasMeta } from "/azp/_work/1/s/src/pages/dashboard/calendar.vue?macro=true";
import { default as favouritesOWw7YCYBlDMeta } from "/azp/_work/1/s/src/pages/dashboard/favourites.vue?macro=true";
import { default as index7GHzzSQlOgMeta } from "/azp/_work/1/s/src/pages/dashboard/index.vue?macro=true";
import { default as insightsrdfMYFjv92Meta } from "/azp/_work/1/s/src/pages/dashboard/insights.vue?macro=true";
import { default as _91id_93YP9vwqivj2Meta } from "/azp/_work/1/s/src/pages/dashboard/messages/[id].vue?macro=true";
import { default as archivesARKfinCOtjMeta } from "/azp/_work/1/s/src/pages/dashboard/messages/archives.vue?macro=true";
import { default as enquiriesm20wPlhQRPMeta } from "/azp/_work/1/s/src/pages/dashboard/messages/enquiries.vue?macro=true";
import { default as indexuJMg7vM9lzMeta } from "/azp/_work/1/s/src/pages/dashboard/messages/index.vue?macro=true";
import { default as requestVZD431ARDnMeta } from "/azp/_work/1/s/src/pages/dashboard/messages/request.vue?macro=true";
import { default as messagesMmyqsCSv9qMeta } from "/azp/_work/1/s/src/pages/dashboard/messages.vue?macro=true";
import { default as _91empty_93E90P4haQ41Meta } from "/azp/_work/1/s/src/pages/dashboard/profile/[empty].vue?macro=true";
import { default as indexWjtAlRo8utMeta } from "/azp/_work/1/s/src/pages/dashboard/profile/drivers/index.vue?macro=true";
import { default as newcGc5eIr0XbMeta } from "/azp/_work/1/s/src/pages/dashboard/profile/drivers/new.vue?macro=true";
import { default as driversKloO5tekjnMeta } from "/azp/_work/1/s/src/pages/dashboard/profile/drivers.vue?macro=true";
import { default as features2YX3kOvZjXMeta } from "/azp/_work/1/s/src/pages/dashboard/profile/features.vue?macro=true";
import { default as indexZYK1RV5OTIMeta } from "/azp/_work/1/s/src/pages/dashboard/profile/index.vue?macro=true";
import { default as message_45templatesSBklG09rdLMeta } from "/azp/_work/1/s/src/pages/dashboard/profile/message-templates.vue?macro=true";
import { default as notificationsIScBOBMXunMeta } from "/azp/_work/1/s/src/pages/dashboard/profile/notifications.vue?macro=true";
import { default as paymentwOUt8JEOqJMeta } from "/azp/_work/1/s/src/pages/dashboard/profile/payment.vue?macro=true";
import { default as payoutX3muz8uZTsMeta } from "/azp/_work/1/s/src/pages/dashboard/profile/payout.vue?macro=true";
import { default as profileVn0BSvDzAIMeta } from "/azp/_work/1/s/src/pages/dashboard/profile.vue?macro=true";
import { default as addonsRT2LKS4pO5Meta } from "/azp/_work/1/s/src/pages/dashboard/rvs/[rvId]/addons.vue?macro=true";
import { default as calendar4MhtmiS0sjMeta } from "/azp/_work/1/s/src/pages/dashboard/rvs/[rvId]/calendar.vue?macro=true";
import { default as detailsDATlRv7mABMeta } from "/azp/_work/1/s/src/pages/dashboard/rvs/[rvId]/details.vue?macro=true";
import { default as earn_45moreM38Z35NmqFMeta } from "/azp/_work/1/s/src/pages/dashboard/rvs/[rvId]/earn-more.vue?macro=true";
import { default as index67AuvbxTteMeta } from "/azp/_work/1/s/src/pages/dashboard/rvs/[rvId]/index.vue?macro=true";
import { default as insurancewM5iJkkoIqMeta } from "/azp/_work/1/s/src/pages/dashboard/rvs/[rvId]/insurance.vue?macro=true";
import { default as photosuWuUkOAMByMeta } from "/azp/_work/1/s/src/pages/dashboard/rvs/[rvId]/photos.vue?macro=true";
import { default as preferencesz4hwaZSXqeMeta } from "/azp/_work/1/s/src/pages/dashboard/rvs/[rvId]/preferences.vue?macro=true";
import { default as pricingOPg73GIj14Meta } from "/azp/_work/1/s/src/pages/dashboard/rvs/[rvId]/pricing.vue?macro=true";
import { default as _91rvId_93vGYEAyDtpkMeta } from "/azp/_work/1/s/src/pages/dashboard/rvs/[rvId].vue?macro=true";
import { default as deletedns7f0ObnuVMeta } from "/azp/_work/1/s/src/pages/dashboard/rvs/deleted.vue?macro=true";
import { default as _91field_93EpVVByBUUZMeta } from "/azp/_work/1/s/src/pages/dashboard/rvs/new/[step]/[field].vue?macro=true";
import { default as indexbH79GWqo8MMeta } from "/azp/_work/1/s/src/pages/dashboard/rvs/new/[step]/index.vue?macro=true";
import { default as newH8kUBht28XMeta } from "/azp/_work/1/s/src/pages/dashboard/rvs/new.vue?macro=true";
import { default as _91id_93YdQn5eHXSFMeta } from "/azp/_work/1/s/src/pages/dashboard/rvs/published/[id].vue?macro=true";
import { default as completedPTTgXrqk1uMeta } from "/azp/_work/1/s/src/pages/dashboard/trips/completed.vue?macro=true";
import { default as indexYClWzlydLwMeta } from "/azp/_work/1/s/src/pages/dashboard/trips/index.vue?macro=true";
import { default as pendingQEs36BY1M1Meta } from "/azp/_work/1/s/src/pages/dashboard/trips/pending.vue?macro=true";
import { default as tripsqDyLEFuZCFMeta } from "/azp/_work/1/s/src/pages/dashboard/trips.vue?macro=true";
import { default as dashboardQOo5U2wjJaMeta } from "/azp/_work/1/s/src/pages/dashboard.vue?macro=true";
import { default as experimentst5Bhy7hZdDMeta } from "/azp/_work/1/s/src/pages/experiments.vue?macro=true";
import { default as how_45it_45worksGkp00edcpJMeta } from "/azp/_work/1/s/src/pages/how-it-works.vue?macro=true";
import { default as logoutVLp8vXnOd9Meta } from "/azp/_work/1/s/src/pages/index/logout.vue?macro=true";
import { default as signincM9ESoargsMeta } from "/azp/_work/1/s/src/pages/index/signin.vue?macro=true";
import { default as signupnebmhd58jwMeta } from "/azp/_work/1/s/src/pages/index/signup.vue?macro=true";
import { default as indexoWEnBdOZkPMeta } from "/azp/_work/1/s/src/pages/index.vue?macro=true";
import { default as insurance_45and_45protectionKXWtY6nOR7Meta } from "/azp/_work/1/s/src/pages/insurance-and-protection.vue?macro=true";
import { default as ownerfejiIrXDYtMeta } from "/azp/_work/1/s/src/pages/owner.vue?macro=true";
import { default as privacy_45policye2FCFoErO0Meta } from "/azp/_work/1/s/src/pages/privacy-policy.vue?macro=true";
import { default as _91id_93eNFzSjT0qdMeta } from "/azp/_work/1/s/src/pages/profile/[id].vue?macro=true";
import { default as _91code_93EF3LjJL1R2Meta } from "/azp/_work/1/s/src/pages/recovery-password/[code].vue?macro=true";
import { default as indexf9oczNIiUqMeta } from "/azp/_work/1/s/src/pages/recovery-password/index.vue?macro=true";
import { default as reviewsUJgp89bNl4Meta } from "/azp/_work/1/s/src/pages/reviews.vue?macro=true";
import { default as rv_45deliveryHc3ZyTMSynMeta } from "/azp/_work/1/s/src/pages/rv-delivery.vue?macro=true";
import { default as indexUqnvKPGUwXMeta } from "/azp/_work/1/s/src/pages/rv-rental/index.vue?macro=true";
import { default as additionalsFv2uPCAqobMeta } from "/azp/_work/1/s/src/pages/rv-rental/request-to-book/[alias]/additionals.vue?macro=true";
import { default as _91alias_93rgbP8kzgDIMeta } from "/azp/_work/1/s/src/pages/rv-rental/request-to-book/[alias].vue?macro=true";
import { default as _91campgroundSlug_93HLlZqkTIiKMeta } from "/azp/_work/1/s/src/pages/rv-rentals/[[keyword]]/campground/[campgroundSlug].vue?macro=true";
import { default as _91_46_46_46destinationPath_93s0eY6wiJLiMeta } from "/azp/_work/1/s/src/pages/rv-rentals/[[keyword]]/destination/[...destinationPath].vue?macro=true";
import { default as indexPHDROACDz0Meta } from "/azp/_work/1/s/src/pages/rv-rentals/[[keyword]]/index.vue?macro=true";
import { default as indexVyiMeeyv8GMeta } from "/azp/_work/1/s/src/pages/rv-rentals/index.vue?macro=true";
import { default as rv_45searchj1OvdynQ19Meta } from "/azp/_work/1/s/src/pages/rv-search.vue?macro=true";
import { default as service_45feesz5h82GVSUvMeta } from "/azp/_work/1/s/src/pages/service-fees.vue?macro=true";
import { default as share_45your_45tripbWbGDr6IQpMeta } from "/azp/_work/1/s/src/pages/share-your-trip.vue?macro=true";
import { default as superhost0oG4Gwj16KMeta } from "/azp/_work/1/s/src/pages/superhost.vue?macro=true";
import { default as terms_45service8OaW0JeIyrMeta } from "/azp/_work/1/s/src/pages/terms-service.vue?macro=true";
import { default as trust_45and_45safety3S9eMdD6YWMeta } from "/azp/_work/1/s/src/pages/trust-and-safety.vue?macro=true";
export default [
  {
    name: "lp-signup___en___default",
    path: "/lp-signup",
    meta: lp_45signupWn0zsVpa1BMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/(marketing)/lp-signup.vue")
  },
  {
    name: "lp-signup___en",
    path: "/en/lp-signup",
    meta: lp_45signupWn0zsVpa1BMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/(marketing)/lp-signup.vue")
  },
  {
    name: "lp-signup___fr",
    path: "/fr/lp-signup",
    meta: lp_45signupWn0zsVpa1BMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/(marketing)/lp-signup.vue")
  },
  {
    name: "about___en___default",
    path: "/about",
    component: () => import("/azp/_work/1/s/src/pages/about.vue")
  },
  {
    name: "about___en",
    path: "/en/about",
    component: () => import("/azp/_work/1/s/src/pages/about.vue")
  },
  {
    name: "about___fr",
    path: "/fr/a-propos",
    component: () => import("/azp/_work/1/s/src/pages/about.vue")
  },
  {
    name: "affiliates___en___default",
    path: "/affiliates",
    component: () => import("/azp/_work/1/s/src/pages/affiliates/index.vue")
  },
  {
    name: "affiliates___en",
    path: "/en/affiliates",
    component: () => import("/azp/_work/1/s/src/pages/affiliates/index.vue")
  },
  {
    name: "affiliates___fr",
    path: "/fr/affilies",
    component: () => import("/azp/_work/1/s/src/pages/affiliates/index.vue")
  },
  {
    name: "app-download___en___default",
    path: "/app-download",
    component: () => import("/azp/_work/1/s/src/pages/app-download.vue")
  },
  {
    name: "app-download___en",
    path: "/en/app-download",
    component: () => import("/azp/_work/1/s/src/pages/app-download.vue")
  },
  {
    name: "app-download___fr",
    path: "/fr/telecharger-application",
    component: () => import("/azp/_work/1/s/src/pages/app-download.vue")
  },
  {
    name: "author-slug___en___default",
    path: "/author/:slug()",
    component: () => import("/azp/_work/1/s/src/pages/author/[slug].vue")
  },
  {
    name: "author-slug___en",
    path: "/en/author/:slug()",
    component: () => import("/azp/_work/1/s/src/pages/author/[slug].vue")
  },
  {
    name: "author-slug___fr",
    path: "/fr/auteur/:slug()",
    component: () => import("/azp/_work/1/s/src/pages/author/[slug].vue")
  },
  {
    name: "blog-slug___en___default",
    path: "/blog/:slug()",
    component: () => import("/azp/_work/1/s/src/pages/blog/[slug].vue")
  },
  {
    name: "blog-slug___en",
    path: "/en/blog/:slug()",
    component: () => import("/azp/_work/1/s/src/pages/blog/[slug].vue")
  },
  {
    name: "blog-slug___fr",
    path: "/fr/blogue/:slug()",
    component: () => import("/azp/_work/1/s/src/pages/blog/[slug].vue")
  },
  {
    name: "blog___en___default",
    path: "/blog",
    component: () => import("/azp/_work/1/s/src/pages/blog/index.vue")
  },
  {
    name: "blog___en",
    path: "/en/blog",
    component: () => import("/azp/_work/1/s/src/pages/blog/index.vue")
  },
  {
    name: "blog___fr",
    path: "/fr/blogue",
    component: () => import("/azp/_work/1/s/src/pages/blog/index.vue")
  },
  {
    name: "callback-oauth___en___default",
    path: "/callback-oauth",
    meta: callback_45oauthFX1VjH5PFRMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/callback-oauth.vue")
  },
  {
    name: "callback-oauth___en",
    path: "/en/callback-oauth",
    meta: callback_45oauthFX1VjH5PFRMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/callback-oauth.vue")
  },
  {
    name: "callback-oauth___fr",
    path: "/fr/callback-oauth",
    meta: callback_45oauthFX1VjH5PFRMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/callback-oauth.vue")
  },
  {
    name: "careers-data-analyst___en___default",
    path: "/careers/spotlight-data-analyst",
    component: () => import("/azp/_work/1/s/src/pages/careers-data-analyst.vue")
  },
  {
    name: "careers-data-analyst___en",
    path: "/en/careers/spotlight-data-analyst",
    component: () => import("/azp/_work/1/s/src/pages/careers-data-analyst.vue")
  },
  {
    name: "careers-data-analyst___fr",
    path: "/fr/careers/spotlight-data-analyst",
    component: () => import("/azp/_work/1/s/src/pages/careers-data-analyst.vue")
  },
  {
    name: "careers-owner-success___en___default",
    path: "/careers/spotlight-owner-success",
    component: () => import("/azp/_work/1/s/src/pages/careers-owner-success.vue")
  },
  {
    name: "careers-owner-success___en",
    path: "/en/careers/spotlight-owner-success",
    component: () => import("/azp/_work/1/s/src/pages/careers-owner-success.vue")
  },
  {
    name: "careers-owner-success___fr",
    path: "/fr/careers/spotlight-owner-success",
    component: () => import("/azp/_work/1/s/src/pages/careers-owner-success.vue")
  },
  {
    name: "careers-trust-and-safety___en___default",
    path: "/careers/spotlight-trust-and-safety",
    component: () => import("/azp/_work/1/s/src/pages/careers-trust-and-safety.vue")
  },
  {
    name: "careers-trust-and-safety___en",
    path: "/en/careers/spotlight-trust-and-safety",
    component: () => import("/azp/_work/1/s/src/pages/careers-trust-and-safety.vue")
  },
  {
    name: "careers-trust-and-safety___fr",
    path: "/fr/careers/spotlight-trust-and-safety",
    component: () => import("/azp/_work/1/s/src/pages/careers-trust-and-safety.vue")
  },
  {
    name: "careers___en___default",
    path: "/careers",
    component: () => import("/azp/_work/1/s/src/pages/careers.vue")
  },
  {
    name: "careers___en",
    path: "/en/careers",
    component: () => import("/azp/_work/1/s/src/pages/careers.vue")
  },
  {
    name: "careers___fr",
    path: "/fr/careers",
    component: () => import("/azp/_work/1/s/src/pages/careers.vue")
  },
  {
    name: "category-slug___en___default",
    path: "/category/:slug()",
    component: () => import("/azp/_work/1/s/src/pages/category/[slug].vue")
  },
  {
    name: "category-slug___en",
    path: "/en/category/:slug()",
    component: () => import("/azp/_work/1/s/src/pages/category/[slug].vue")
  },
  {
    name: "category-slug___fr",
    path: "/fr/categorie/:slug()",
    component: () => import("/azp/_work/1/s/src/pages/category/[slug].vue")
  },
  {
    name: dashboardQOo5U2wjJaMeta?.name,
    path: "/dashboard",
    meta: dashboardQOo5U2wjJaMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard.vue"),
    children: [
  {
    name: agreementOJG6XG7AbVMeta?.name,
    path: "bookings/:id()/agreement",
    meta: agreementOJG6XG7AbVMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/agreement.vue"),
    children: [
  {
    name: "dashboard-bookings-id-agreement-error___en___default",
    path: "error",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/agreement/error.vue")
  },
  {
    name: "dashboard-bookings-id-agreement___en___default",
    path: "",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/agreement/index.vue")
  },
  {
    name: "dashboard-bookings-id-agreement-signature___en___default",
    path: "signature",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/agreement/signature.vue")
  },
  {
    name: "dashboard-bookings-id-agreement-success___en___default",
    path: "success",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/agreement/success.vue")
  }
]
  },
  {
    name: change_45requestN2GGi9yVslMeta?.name,
    path: "bookings/:id()/change-request",
    meta: change_45requestN2GGi9yVslMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/change-request.vue"),
    children: [
  {
    name: "dashboard-bookings-id-change-request-accept___en___default",
    path: "accept",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/change-request/accept.vue")
  },
  {
    name: "dashboard-bookings-id-change-request-decline___en___default",
    path: "decline",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/change-request/decline.vue")
  },
  {
    name: "dashboard-bookings-id-change-request___en___default",
    path: "",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/change-request/index.vue")
  },
  {
    name: "dashboard-bookings-id-change-request-review___en___default",
    path: "review",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/change-request/review.vue")
  }
]
  },
  {
    name: "dashboard-bookings-id-custom-quote___en___default",
    path: "bookings/:id()/custom-quote",
    meta: custom_45quoteTtQH5jQi7cMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/custom-quote.vue")
  },
  {
    name: indexueu9EO6uwXMeta?.name,
    path: "bookings/:id()",
    meta: indexueu9EO6uwXMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index.vue"),
    children: [
  {
    name: "dashboard-bookings-id-index-accept___en___default",
    path: "accept",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/accept.vue")
  },
  {
    name: "dashboard-bookings-id-index-accepted___en___default",
    path: "accepted",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/accepted.vue")
  },
  {
    name: "dashboard-bookings-id-index-cancel___en___default",
    path: "cancel",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/cancel.vue")
  },
  {
    name: "dashboard-bookings-id-index-closeout___en___default",
    path: "closeout",
    meta: closeoutFVbPPtIMsxMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/closeout.vue")
  },
  {
    name: "dashboard-bookings-id-index-complete___en___default",
    path: "complete",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/complete.vue")
  },
  {
    name: "dashboard-bookings-id-index-decline___en___default",
    path: "decline",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/decline.vue")
  },
  {
    name: "dashboard-bookings-id-index-documents___en___default",
    path: "documents",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/documents.vue")
  },
  {
    name: editQ4NvAouGfdMeta?.name,
    path: "edit",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/edit.vue"),
    children: [
  {
    name: "dashboard-bookings-id-index-edit-addons___en___default",
    path: "addons",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/edit/addons.vue")
  },
  {
    name: "dashboard-bookings-id-index-edit-dates___en___default",
    path: "dates",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/edit/dates.vue")
  },
  {
    name: "dashboard-bookings-id-index-edit-destination___en___default",
    path: "destination",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/edit/destination.vue")
  },
  {
    name: "dashboard-bookings-id-index-edit-details___en___default",
    path: "details",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/edit/details.vue")
  },
  {
    name: "dashboard-bookings-id-index-edit___en___default",
    path: "",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/edit/index.vue")
  },
  {
    name: "dashboard-bookings-id-index-edit-pending___en___default",
    path: "pending",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/edit/pending.vue")
  },
  {
    name: "dashboard-bookings-id-index-edit-review___en___default",
    path: "review",
    meta: reviewTOGyYRz7pcMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/edit/review.vue")
  }
]
  },
  {
    name: "dashboard-bookings-id-index-full-review___en___default",
    path: "full-review",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/full-review.vue")
  },
  {
    name: "dashboard-bookings-id-index-income-protection___en___default",
    path: "income-protection",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/income-protection.vue")
  },
  {
    name: "dashboard-bookings-id-index___en___default",
    path: "",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/index.vue")
  },
  {
    name: "dashboard-bookings-id-index-insurance___en___default",
    path: "insurance",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/insurance.vue")
  }
]
  },
  {
    name: "dashboard-bookings-id-insurance-efnol___en___default",
    path: "bookings/:id()/insurance-efnol",
    meta: insurance_45efnol7NfsxsrzCQMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/insurance-efnol.vue")
  },
  {
    name: "dashboard-bookings-id-invoice___en___default",
    path: "bookings/:id()/invoice",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/invoice.vue")
  },
  {
    name: "dashboard-bookings-id-payment___en___default",
    path: "bookings/:id()/payment",
    meta: paymentMVdsGZBEp2Meta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/payment.vue")
  },
  {
    name: "dashboard-bookings-id-retry-payment___en___default",
    path: "bookings/:id()/retry-payment",
    meta: retry_45paymentpQqRlfCYgqMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/retry-payment.vue")
  },
  {
    name: "dashboard-bookings-id-review___en___default",
    path: "bookings/:id()/review",
    meta: reviewM34FOT85nlMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/review.vue")
  },
  {
    name: send_45request_45moneyOfvoMOOThlMeta?.name,
    path: "bookings/:id()/send-request-money",
    meta: send_45request_45moneyOfvoMOOThlMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/send-request-money.vue"),
    children: [
  {
    name: "dashboard-bookings-id-send-request-money-dispute___en___default",
    path: "dispute",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/dispute.vue")
  },
  {
    name: "dashboard-bookings-id-send-request-money___en___default",
    path: "",
    meta: indexejG2mrHQH0Meta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/index.vue")
  },
  {
    name: "dashboard-bookings-id-send-request-money-request___en___default",
    path: "request",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/request.vue")
  },
  {
    name: "dashboard-bookings-id-send-request-money-review___en___default",
    path: "review",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/review.vue")
  },
  {
    name: "dashboard-bookings-id-send-request-money-send___en___default",
    path: "send",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/send.vue")
  },
  {
    name: "dashboard-bookings-id-send-request-money-sent___en___default",
    path: "sent",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/sent.vue")
  },
  {
    name: "dashboard-bookings-id-send-request-money-success___en___default",
    path: "success",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/success.vue")
  }
]
  },
  {
    name: updatek0Nn08AfBHMeta?.name,
    path: "bookings/:id()/services/update",
    meta: updatek0Nn08AfBHMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/services/update.vue"),
    children: [
  {
    name: "dashboard-bookings-id-services-update-complete___en___default",
    path: "complete",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/services/update/complete.vue")
  },
  {
    name: "dashboard-bookings-id-services-update___en___default",
    path: "",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/services/update/index.vue")
  },
  {
    name: "dashboard-bookings-id-services-update-review___en___default",
    path: "review",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/services/update/review.vue")
  }
]
  },
  {
    name: "dashboard-bookings-id-update-payment___en___default",
    path: "bookings/:id()/update-payment",
    meta: update_45payment5qC5N0BtZhMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/update-payment.vue")
  },
  {
    name: indexMP4Q01KBUlMeta?.name,
    path: "bookings",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/index.vue"),
    children: [
  {
    name: "dashboard-bookings-index-completed___en___default",
    path: "completed",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/index/completed.vue")
  },
  {
    name: "dashboard-bookings-index-confirmed___en___default",
    path: "confirmed",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/index/confirmed.vue")
  },
  {
    name: "dashboard-bookings-index-ezymatch___en___default",
    path: "ezymatch",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/index/ezymatch.vue")
  },
  {
    name: "dashboard-bookings-index___en___default",
    path: "",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/index/index.vue")
  },
  {
    name: "dashboard-bookings-index-pending___en___default",
    path: "pending",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/index/pending.vue")
  }
]
  },
  {
    name: "dashboard-calendar___en___default",
    path: "calendar",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/calendar.vue")
  },
  {
    name: "dashboard-favourites___en___default",
    path: "favourites",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/favourites.vue")
  },
  {
    name: "dashboard___en___default",
    path: "",
    meta: index7GHzzSQlOgMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-insights___en___default",
    path: "insights",
    meta: insightsrdfMYFjv92Meta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/insights.vue")
  },
  {
    name: messagesMmyqsCSv9qMeta?.name,
    path: "messages",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/messages.vue"),
    children: [
  {
    name: "dashboard-messages-id___en___default",
    path: ":id()",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/messages/[id].vue")
  },
  {
    name: "dashboard-messages-archives___en___default",
    path: "archives",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/messages/archives.vue")
  },
  {
    name: "dashboard-messages-enquiries___en___default",
    path: "enquiries",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/messages/enquiries.vue")
  },
  {
    name: "dashboard-messages___en___default",
    path: "",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/messages/index.vue")
  },
  {
    name: "dashboard-messages-request___en___default",
    path: "request",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/messages/request.vue")
  }
]
  },
  {
    name: profileVn0BSvDzAIMeta?.name,
    path: "profile",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/profile.vue"),
    children: [
  {
    name: "dashboard-profile-empty___en___default",
    path: ":empty()",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/profile/[empty].vue")
  },
  {
    name: driversKloO5tekjnMeta?.name,
    path: "drivers",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/profile/drivers.vue"),
    children: [
  {
    name: "dashboard-profile-drivers___en___default",
    path: "",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/profile/drivers/index.vue")
  },
  {
    name: "dashboard-profile-drivers-new___en___default",
    path: "new",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/profile/drivers/new.vue")
  }
]
  },
  {
    name: "dashboard-profile-features___en___default",
    path: "features",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/profile/features.vue")
  },
  {
    name: "dashboard-profile___en___default",
    path: "",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/profile/index.vue")
  },
  {
    name: "dashboard-profile-message-templates___en___default",
    path: "message-templates",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/profile/message-templates.vue")
  },
  {
    name: "dashboard-profile-notifications___en___default",
    path: "notifications",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/profile/notifications.vue")
  },
  {
    name: "dashboard-profile-payment___en___default",
    path: "payment",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/profile/payment.vue")
  },
  {
    name: "dashboard-profile-payout___en___default",
    path: "payout",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/profile/payout.vue")
  }
]
  },
  {
    name: _91rvId_93vGYEAyDtpkMeta?.name,
    path: "rvs/:rvId()",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/[rvId].vue"),
    children: [
  {
    name: "dashboard-rvs-rvId-addons___en___default",
    path: "addons",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/[rvId]/addons.vue")
  },
  {
    name: "dashboard-rvs-rvId-calendar___en___default",
    path: "calendar",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/[rvId]/calendar.vue")
  },
  {
    name: "dashboard-rvs-rvId-details___en___default",
    path: "details",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/[rvId]/details.vue")
  },
  {
    name: "dashboard-rvs-rvId-earn-more___en___default",
    path: "earn-more",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/[rvId]/earn-more.vue")
  },
  {
    name: "dashboard-rvs-rvId___en___default",
    path: "",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/[rvId]/index.vue")
  },
  {
    name: "dashboard-rvs-rvId-insurance___en___default",
    path: "insurance",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/[rvId]/insurance.vue")
  },
  {
    name: "dashboard-rvs-rvId-photos___en___default",
    path: "photos",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/[rvId]/photos.vue")
  },
  {
    name: "dashboard-rvs-rvId-preferences___en___default",
    path: "preferences",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/[rvId]/preferences.vue")
  },
  {
    name: "dashboard-rvs-rvId-pricing___en___default",
    path: "pricing",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/[rvId]/pricing.vue")
  }
]
  },
  {
    name: "dashboard-rvs-deleted___en___default",
    path: "rvs/deleted",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/deleted.vue")
  },
  {
    name: "dashboard-rvs-new___en___default",
    path: "rvs/new",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/new.vue"),
    children: [
  {
    name: "dashboard-rvs-new-step-field___en___default",
    path: "/dashboard/:step()/:field()",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/new/[step]/[field].vue")
  },
  {
    name: "dashboard-rvs-new-step___en___default",
    path: "/dashboard/:step()",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/new/[step]/index.vue")
  }
]
  },
  {
    name: "dashboard-rvs-published-id___en___default",
    path: "rvs/published/:id()",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/published/[id].vue")
  },
  {
    name: tripsqDyLEFuZCFMeta?.name,
    path: "trips",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/trips.vue"),
    children: [
  {
    name: "dashboard-trips-completed___en___default",
    path: "completed",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/trips/completed.vue")
  },
  {
    name: "dashboard-trips___en___default",
    path: "",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/trips/index.vue")
  },
  {
    name: "dashboard-trips-pending___en___default",
    path: "pending",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/trips/pending.vue")
  }
]
  }
]
  },
  {
    name: dashboardQOo5U2wjJaMeta?.name,
    path: "/en/dashboard",
    meta: dashboardQOo5U2wjJaMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard.vue"),
    children: [
  {
    name: agreementOJG6XG7AbVMeta?.name,
    path: "bookings/:id()/agreement",
    meta: agreementOJG6XG7AbVMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/agreement.vue"),
    children: [
  {
    name: "dashboard-bookings-id-agreement-error___en",
    path: "error",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/agreement/error.vue")
  },
  {
    name: "dashboard-bookings-id-agreement___en",
    path: "",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/agreement/index.vue")
  },
  {
    name: "dashboard-bookings-id-agreement-signature___en",
    path: "signature",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/agreement/signature.vue")
  },
  {
    name: "dashboard-bookings-id-agreement-success___en",
    path: "success",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/agreement/success.vue")
  }
]
  },
  {
    name: change_45requestN2GGi9yVslMeta?.name,
    path: "bookings/:id()/change-request",
    meta: change_45requestN2GGi9yVslMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/change-request.vue"),
    children: [
  {
    name: "dashboard-bookings-id-change-request-accept___en",
    path: "accept",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/change-request/accept.vue")
  },
  {
    name: "dashboard-bookings-id-change-request-decline___en",
    path: "decline",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/change-request/decline.vue")
  },
  {
    name: "dashboard-bookings-id-change-request___en",
    path: "",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/change-request/index.vue")
  },
  {
    name: "dashboard-bookings-id-change-request-review___en",
    path: "review",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/change-request/review.vue")
  }
]
  },
  {
    name: "dashboard-bookings-id-custom-quote___en",
    path: "bookings/:id()/custom-quote",
    meta: custom_45quoteTtQH5jQi7cMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/custom-quote.vue")
  },
  {
    name: indexueu9EO6uwXMeta?.name,
    path: "bookings/:id()",
    meta: indexueu9EO6uwXMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index.vue"),
    children: [
  {
    name: "dashboard-bookings-id-index-accept___en",
    path: "accept",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/accept.vue")
  },
  {
    name: "dashboard-bookings-id-index-accepted___en",
    path: "accepted",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/accepted.vue")
  },
  {
    name: "dashboard-bookings-id-index-cancel___en",
    path: "cancel",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/cancel.vue")
  },
  {
    name: "dashboard-bookings-id-index-closeout___en",
    path: "closeout",
    meta: closeoutFVbPPtIMsxMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/closeout.vue")
  },
  {
    name: "dashboard-bookings-id-index-complete___en",
    path: "complete",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/complete.vue")
  },
  {
    name: "dashboard-bookings-id-index-decline___en",
    path: "decline",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/decline.vue")
  },
  {
    name: "dashboard-bookings-id-index-documents___en",
    path: "documents",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/documents.vue")
  },
  {
    name: editQ4NvAouGfdMeta?.name,
    path: "edit",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/edit.vue"),
    children: [
  {
    name: "dashboard-bookings-id-index-edit-addons___en",
    path: "addons",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/edit/addons.vue")
  },
  {
    name: "dashboard-bookings-id-index-edit-dates___en",
    path: "dates",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/edit/dates.vue")
  },
  {
    name: "dashboard-bookings-id-index-edit-destination___en",
    path: "destination",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/edit/destination.vue")
  },
  {
    name: "dashboard-bookings-id-index-edit-details___en",
    path: "details",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/edit/details.vue")
  },
  {
    name: "dashboard-bookings-id-index-edit___en",
    path: "",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/edit/index.vue")
  },
  {
    name: "dashboard-bookings-id-index-edit-pending___en",
    path: "pending",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/edit/pending.vue")
  },
  {
    name: "dashboard-bookings-id-index-edit-review___en",
    path: "review",
    meta: reviewTOGyYRz7pcMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/edit/review.vue")
  }
]
  },
  {
    name: "dashboard-bookings-id-index-full-review___en",
    path: "full-review",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/full-review.vue")
  },
  {
    name: "dashboard-bookings-id-index-income-protection___en",
    path: "income-protection",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/income-protection.vue")
  },
  {
    name: "dashboard-bookings-id-index___en",
    path: "",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/index.vue")
  },
  {
    name: "dashboard-bookings-id-index-insurance___en",
    path: "insurance",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/insurance.vue")
  }
]
  },
  {
    name: "dashboard-bookings-id-insurance-efnol___en",
    path: "bookings/:id()/insurance-efnol",
    meta: insurance_45efnol7NfsxsrzCQMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/insurance-efnol.vue")
  },
  {
    name: "dashboard-bookings-id-invoice___en",
    path: "bookings/:id()/invoice",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/invoice.vue")
  },
  {
    name: "dashboard-bookings-id-payment___en",
    path: "bookings/:id()/payment",
    meta: paymentMVdsGZBEp2Meta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/payment.vue")
  },
  {
    name: "dashboard-bookings-id-retry-payment___en",
    path: "bookings/:id()/retry-payment",
    meta: retry_45paymentpQqRlfCYgqMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/retry-payment.vue")
  },
  {
    name: "dashboard-bookings-id-review___en",
    path: "bookings/:id()/review",
    meta: reviewM34FOT85nlMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/review.vue")
  },
  {
    name: send_45request_45moneyOfvoMOOThlMeta?.name,
    path: "bookings/:id()/send-request-money",
    meta: send_45request_45moneyOfvoMOOThlMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/send-request-money.vue"),
    children: [
  {
    name: "dashboard-bookings-id-send-request-money-dispute___en",
    path: "dispute",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/dispute.vue")
  },
  {
    name: "dashboard-bookings-id-send-request-money___en",
    path: "",
    meta: indexejG2mrHQH0Meta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/index.vue")
  },
  {
    name: "dashboard-bookings-id-send-request-money-request___en",
    path: "request",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/request.vue")
  },
  {
    name: "dashboard-bookings-id-send-request-money-review___en",
    path: "review",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/review.vue")
  },
  {
    name: "dashboard-bookings-id-send-request-money-send___en",
    path: "send",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/send.vue")
  },
  {
    name: "dashboard-bookings-id-send-request-money-sent___en",
    path: "sent",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/sent.vue")
  },
  {
    name: "dashboard-bookings-id-send-request-money-success___en",
    path: "success",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/success.vue")
  }
]
  },
  {
    name: updatek0Nn08AfBHMeta?.name,
    path: "bookings/:id()/services/update",
    meta: updatek0Nn08AfBHMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/services/update.vue"),
    children: [
  {
    name: "dashboard-bookings-id-services-update-complete___en",
    path: "complete",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/services/update/complete.vue")
  },
  {
    name: "dashboard-bookings-id-services-update___en",
    path: "",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/services/update/index.vue")
  },
  {
    name: "dashboard-bookings-id-services-update-review___en",
    path: "review",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/services/update/review.vue")
  }
]
  },
  {
    name: "dashboard-bookings-id-update-payment___en",
    path: "bookings/:id()/update-payment",
    meta: update_45payment5qC5N0BtZhMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/update-payment.vue")
  },
  {
    name: indexMP4Q01KBUlMeta?.name,
    path: "bookings",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/index.vue"),
    children: [
  {
    name: "dashboard-bookings-index-completed___en",
    path: "completed",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/index/completed.vue")
  },
  {
    name: "dashboard-bookings-index-confirmed___en",
    path: "confirmed",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/index/confirmed.vue")
  },
  {
    name: "dashboard-bookings-index-ezymatch___en",
    path: "ezymatch",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/index/ezymatch.vue")
  },
  {
    name: "dashboard-bookings-index___en",
    path: "",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/index/index.vue")
  },
  {
    name: "dashboard-bookings-index-pending___en",
    path: "pending",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/index/pending.vue")
  }
]
  },
  {
    name: "dashboard-calendar___en",
    path: "calendar",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/calendar.vue")
  },
  {
    name: "dashboard-favourites___en",
    path: "favourites",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/favourites.vue")
  },
  {
    name: "dashboard___en",
    path: "",
    meta: index7GHzzSQlOgMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-insights___en",
    path: "insights",
    meta: insightsrdfMYFjv92Meta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/insights.vue")
  },
  {
    name: messagesMmyqsCSv9qMeta?.name,
    path: "messages",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/messages.vue"),
    children: [
  {
    name: "dashboard-messages-id___en",
    path: ":id()",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/messages/[id].vue")
  },
  {
    name: "dashboard-messages-archives___en",
    path: "archives",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/messages/archives.vue")
  },
  {
    name: "dashboard-messages-enquiries___en",
    path: "enquiries",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/messages/enquiries.vue")
  },
  {
    name: "dashboard-messages___en",
    path: "",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/messages/index.vue")
  },
  {
    name: "dashboard-messages-request___en",
    path: "request",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/messages/request.vue")
  }
]
  },
  {
    name: profileVn0BSvDzAIMeta?.name,
    path: "profile",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/profile.vue"),
    children: [
  {
    name: "dashboard-profile-empty___en",
    path: ":empty()",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/profile/[empty].vue")
  },
  {
    name: driversKloO5tekjnMeta?.name,
    path: "drivers",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/profile/drivers.vue"),
    children: [
  {
    name: "dashboard-profile-drivers___en",
    path: "",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/profile/drivers/index.vue")
  },
  {
    name: "dashboard-profile-drivers-new___en",
    path: "new",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/profile/drivers/new.vue")
  }
]
  },
  {
    name: "dashboard-profile-features___en",
    path: "features",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/profile/features.vue")
  },
  {
    name: "dashboard-profile___en",
    path: "",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/profile/index.vue")
  },
  {
    name: "dashboard-profile-message-templates___en",
    path: "message-templates",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/profile/message-templates.vue")
  },
  {
    name: "dashboard-profile-notifications___en",
    path: "notifications",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/profile/notifications.vue")
  },
  {
    name: "dashboard-profile-payment___en",
    path: "payment",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/profile/payment.vue")
  },
  {
    name: "dashboard-profile-payout___en",
    path: "payout",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/profile/payout.vue")
  }
]
  },
  {
    name: _91rvId_93vGYEAyDtpkMeta?.name,
    path: "rvs/:rvId()",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/[rvId].vue"),
    children: [
  {
    name: "dashboard-rvs-rvId-addons___en",
    path: "addons",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/[rvId]/addons.vue")
  },
  {
    name: "dashboard-rvs-rvId-calendar___en",
    path: "calendar",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/[rvId]/calendar.vue")
  },
  {
    name: "dashboard-rvs-rvId-details___en",
    path: "details",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/[rvId]/details.vue")
  },
  {
    name: "dashboard-rvs-rvId-earn-more___en",
    path: "earn-more",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/[rvId]/earn-more.vue")
  },
  {
    name: "dashboard-rvs-rvId___en",
    path: "",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/[rvId]/index.vue")
  },
  {
    name: "dashboard-rvs-rvId-insurance___en",
    path: "insurance",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/[rvId]/insurance.vue")
  },
  {
    name: "dashboard-rvs-rvId-photos___en",
    path: "photos",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/[rvId]/photos.vue")
  },
  {
    name: "dashboard-rvs-rvId-preferences___en",
    path: "preferences",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/[rvId]/preferences.vue")
  },
  {
    name: "dashboard-rvs-rvId-pricing___en",
    path: "pricing",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/[rvId]/pricing.vue")
  }
]
  },
  {
    name: "dashboard-rvs-deleted___en",
    path: "rvs/deleted",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/deleted.vue")
  },
  {
    name: "dashboard-rvs-new___en",
    path: "rvs/new",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/new.vue"),
    children: [
  {
    name: "dashboard-rvs-new-step-field___en",
    path: "/en/dashboard/:step()/:field()",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/new/[step]/[field].vue")
  },
  {
    name: "dashboard-rvs-new-step___en",
    path: "/en/dashboard/:step()",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/new/[step]/index.vue")
  }
]
  },
  {
    name: "dashboard-rvs-published-id___en",
    path: "rvs/published/:id()",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/published/[id].vue")
  },
  {
    name: tripsqDyLEFuZCFMeta?.name,
    path: "trips",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/trips.vue"),
    children: [
  {
    name: "dashboard-trips-completed___en",
    path: "completed",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/trips/completed.vue")
  },
  {
    name: "dashboard-trips___en",
    path: "",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/trips/index.vue")
  },
  {
    name: "dashboard-trips-pending___en",
    path: "pending",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/trips/pending.vue")
  }
]
  }
]
  },
  {
    name: dashboardQOo5U2wjJaMeta?.name,
    path: "/fr/dashboard",
    meta: dashboardQOo5U2wjJaMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard.vue"),
    children: [
  {
    name: agreementOJG6XG7AbVMeta?.name,
    path: "bookings/:id()/agreement",
    meta: agreementOJG6XG7AbVMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/agreement.vue"),
    children: [
  {
    name: "dashboard-bookings-id-agreement-error___fr",
    path: "error",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/agreement/error.vue")
  },
  {
    name: "dashboard-bookings-id-agreement___fr",
    path: "",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/agreement/index.vue")
  },
  {
    name: "dashboard-bookings-id-agreement-signature___fr",
    path: "signature",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/agreement/signature.vue")
  },
  {
    name: "dashboard-bookings-id-agreement-success___fr",
    path: "success",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/agreement/success.vue")
  }
]
  },
  {
    name: change_45requestN2GGi9yVslMeta?.name,
    path: "bookings/:id()/change-request",
    meta: change_45requestN2GGi9yVslMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/change-request.vue"),
    children: [
  {
    name: "dashboard-bookings-id-change-request-accept___fr",
    path: "accept",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/change-request/accept.vue")
  },
  {
    name: "dashboard-bookings-id-change-request-decline___fr",
    path: "decline",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/change-request/decline.vue")
  },
  {
    name: "dashboard-bookings-id-change-request___fr",
    path: "",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/change-request/index.vue")
  },
  {
    name: "dashboard-bookings-id-change-request-review___fr",
    path: "review",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/change-request/review.vue")
  }
]
  },
  {
    name: "dashboard-bookings-id-custom-quote___fr",
    path: "bookings/:id()/custom-quote",
    meta: custom_45quoteTtQH5jQi7cMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/custom-quote.vue")
  },
  {
    name: indexueu9EO6uwXMeta?.name,
    path: "bookings/:id()",
    meta: indexueu9EO6uwXMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index.vue"),
    children: [
  {
    name: "dashboard-bookings-id-index-accept___fr",
    path: "accept",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/accept.vue")
  },
  {
    name: "dashboard-bookings-id-index-accepted___fr",
    path: "accepted",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/accepted.vue")
  },
  {
    name: "dashboard-bookings-id-index-cancel___fr",
    path: "cancel",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/cancel.vue")
  },
  {
    name: "dashboard-bookings-id-index-closeout___fr",
    path: "closeout",
    meta: closeoutFVbPPtIMsxMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/closeout.vue")
  },
  {
    name: "dashboard-bookings-id-index-complete___fr",
    path: "complete",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/complete.vue")
  },
  {
    name: "dashboard-bookings-id-index-decline___fr",
    path: "decline",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/decline.vue")
  },
  {
    name: "dashboard-bookings-id-index-documents___fr",
    path: "documents",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/documents.vue")
  },
  {
    name: editQ4NvAouGfdMeta?.name,
    path: "edit",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/edit.vue"),
    children: [
  {
    name: "dashboard-bookings-id-index-edit-addons___fr",
    path: "addons",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/edit/addons.vue")
  },
  {
    name: "dashboard-bookings-id-index-edit-dates___fr",
    path: "dates",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/edit/dates.vue")
  },
  {
    name: "dashboard-bookings-id-index-edit-destination___fr",
    path: "destination",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/edit/destination.vue")
  },
  {
    name: "dashboard-bookings-id-index-edit-details___fr",
    path: "details",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/edit/details.vue")
  },
  {
    name: "dashboard-bookings-id-index-edit___fr",
    path: "",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/edit/index.vue")
  },
  {
    name: "dashboard-bookings-id-index-edit-pending___fr",
    path: "pending",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/edit/pending.vue")
  },
  {
    name: "dashboard-bookings-id-index-edit-review___fr",
    path: "review",
    meta: reviewTOGyYRz7pcMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/edit/review.vue")
  }
]
  },
  {
    name: "dashboard-bookings-id-index-full-review___fr",
    path: "full-review",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/full-review.vue")
  },
  {
    name: "dashboard-bookings-id-index-income-protection___fr",
    path: "income-protection",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/income-protection.vue")
  },
  {
    name: "dashboard-bookings-id-index___fr",
    path: "",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/index.vue")
  },
  {
    name: "dashboard-bookings-id-index-insurance___fr",
    path: "insurance",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/index/insurance.vue")
  }
]
  },
  {
    name: "dashboard-bookings-id-insurance-efnol___fr",
    path: "bookings/:id()/insurance-efnol",
    meta: insurance_45efnol7NfsxsrzCQMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/insurance-efnol.vue")
  },
  {
    name: "dashboard-bookings-id-invoice___fr",
    path: "bookings/:id()/invoice",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/invoice.vue")
  },
  {
    name: "dashboard-bookings-id-payment___fr",
    path: "bookings/:id()/payment",
    meta: paymentMVdsGZBEp2Meta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/payment.vue")
  },
  {
    name: "dashboard-bookings-id-retry-payment___fr",
    path: "bookings/:id()/retry-payment",
    meta: retry_45paymentpQqRlfCYgqMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/retry-payment.vue")
  },
  {
    name: "dashboard-bookings-id-review___fr",
    path: "bookings/:id()/review",
    meta: reviewM34FOT85nlMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/review.vue")
  },
  {
    name: send_45request_45moneyOfvoMOOThlMeta?.name,
    path: "bookings/:id()/send-request-money",
    meta: send_45request_45moneyOfvoMOOThlMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/send-request-money.vue"),
    children: [
  {
    name: "dashboard-bookings-id-send-request-money-dispute___fr",
    path: "dispute",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/dispute.vue")
  },
  {
    name: "dashboard-bookings-id-send-request-money___fr",
    path: "",
    meta: indexejG2mrHQH0Meta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/index.vue")
  },
  {
    name: "dashboard-bookings-id-send-request-money-request___fr",
    path: "request",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/request.vue")
  },
  {
    name: "dashboard-bookings-id-send-request-money-review___fr",
    path: "review",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/review.vue")
  },
  {
    name: "dashboard-bookings-id-send-request-money-send___fr",
    path: "send",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/send.vue")
  },
  {
    name: "dashboard-bookings-id-send-request-money-sent___fr",
    path: "sent",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/sent.vue")
  },
  {
    name: "dashboard-bookings-id-send-request-money-success___fr",
    path: "success",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/success.vue")
  }
]
  },
  {
    name: updatek0Nn08AfBHMeta?.name,
    path: "bookings/:id()/services/update",
    meta: updatek0Nn08AfBHMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/services/update.vue"),
    children: [
  {
    name: "dashboard-bookings-id-services-update-complete___fr",
    path: "complete",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/services/update/complete.vue")
  },
  {
    name: "dashboard-bookings-id-services-update___fr",
    path: "",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/services/update/index.vue")
  },
  {
    name: "dashboard-bookings-id-services-update-review___fr",
    path: "review",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/services/update/review.vue")
  }
]
  },
  {
    name: "dashboard-bookings-id-update-payment___fr",
    path: "bookings/:id()/update-payment",
    meta: update_45payment5qC5N0BtZhMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/[id]/update-payment.vue")
  },
  {
    name: indexMP4Q01KBUlMeta?.name,
    path: "bookings",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/index.vue"),
    children: [
  {
    name: "dashboard-bookings-index-completed___fr",
    path: "completed",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/index/completed.vue")
  },
  {
    name: "dashboard-bookings-index-confirmed___fr",
    path: "confirmed",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/index/confirmed.vue")
  },
  {
    name: "dashboard-bookings-index-ezymatch___fr",
    path: "ezymatch",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/index/ezymatch.vue")
  },
  {
    name: "dashboard-bookings-index___fr",
    path: "",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/index/index.vue")
  },
  {
    name: "dashboard-bookings-index-pending___fr",
    path: "pending",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/bookings/index/pending.vue")
  }
]
  },
  {
    name: "dashboard-calendar___fr",
    path: "calendar",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/calendar.vue")
  },
  {
    name: "dashboard-favourites___fr",
    path: "favourites",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/favourites.vue")
  },
  {
    name: "dashboard___fr",
    path: "",
    meta: index7GHzzSQlOgMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-insights___fr",
    path: "insights",
    meta: insightsrdfMYFjv92Meta || {},
    component: () => import("/azp/_work/1/s/src/pages/dashboard/insights.vue")
  },
  {
    name: messagesMmyqsCSv9qMeta?.name,
    path: "messages",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/messages.vue"),
    children: [
  {
    name: "dashboard-messages-id___fr",
    path: ":id()",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/messages/[id].vue")
  },
  {
    name: "dashboard-messages-archives___fr",
    path: "archives",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/messages/archives.vue")
  },
  {
    name: "dashboard-messages-enquiries___fr",
    path: "enquiries",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/messages/enquiries.vue")
  },
  {
    name: "dashboard-messages___fr",
    path: "",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/messages/index.vue")
  },
  {
    name: "dashboard-messages-request___fr",
    path: "request",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/messages/request.vue")
  }
]
  },
  {
    name: profileVn0BSvDzAIMeta?.name,
    path: "profile",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/profile.vue"),
    children: [
  {
    name: "dashboard-profile-empty___fr",
    path: ":empty()",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/profile/[empty].vue")
  },
  {
    name: driversKloO5tekjnMeta?.name,
    path: "drivers",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/profile/drivers.vue"),
    children: [
  {
    name: "dashboard-profile-drivers___fr",
    path: "",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/profile/drivers/index.vue")
  },
  {
    name: "dashboard-profile-drivers-new___fr",
    path: "new",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/profile/drivers/new.vue")
  }
]
  },
  {
    name: "dashboard-profile-features___fr",
    path: "features",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/profile/features.vue")
  },
  {
    name: "dashboard-profile___fr",
    path: "",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/profile/index.vue")
  },
  {
    name: "dashboard-profile-message-templates___fr",
    path: "message-templates",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/profile/message-templates.vue")
  },
  {
    name: "dashboard-profile-notifications___fr",
    path: "notifications",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/profile/notifications.vue")
  },
  {
    name: "dashboard-profile-payment___fr",
    path: "payment",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/profile/payment.vue")
  },
  {
    name: "dashboard-profile-payout___fr",
    path: "payout",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/profile/payout.vue")
  }
]
  },
  {
    name: _91rvId_93vGYEAyDtpkMeta?.name,
    path: "rvs/:rvId()",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/[rvId].vue"),
    children: [
  {
    name: "dashboard-rvs-rvId-addons___fr",
    path: "addons",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/[rvId]/addons.vue")
  },
  {
    name: "dashboard-rvs-rvId-calendar___fr",
    path: "calendar",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/[rvId]/calendar.vue")
  },
  {
    name: "dashboard-rvs-rvId-details___fr",
    path: "details",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/[rvId]/details.vue")
  },
  {
    name: "dashboard-rvs-rvId-earn-more___fr",
    path: "earn-more",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/[rvId]/earn-more.vue")
  },
  {
    name: "dashboard-rvs-rvId___fr",
    path: "",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/[rvId]/index.vue")
  },
  {
    name: "dashboard-rvs-rvId-insurance___fr",
    path: "insurance",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/[rvId]/insurance.vue")
  },
  {
    name: "dashboard-rvs-rvId-photos___fr",
    path: "photos",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/[rvId]/photos.vue")
  },
  {
    name: "dashboard-rvs-rvId-preferences___fr",
    path: "preferences",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/[rvId]/preferences.vue")
  },
  {
    name: "dashboard-rvs-rvId-pricing___fr",
    path: "pricing",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/[rvId]/pricing.vue")
  }
]
  },
  {
    name: "dashboard-rvs-deleted___fr",
    path: "rvs/deleted",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/deleted.vue")
  },
  {
    name: "dashboard-rvs-new___fr",
    path: "rvs/new",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/new.vue"),
    children: [
  {
    name: "dashboard-rvs-new-step-field___fr",
    path: "/fr/dashboard/:step()/:field()",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/new/[step]/[field].vue")
  },
  {
    name: "dashboard-rvs-new-step___fr",
    path: "/fr/dashboard/:step()",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/new/[step]/index.vue")
  }
]
  },
  {
    name: "dashboard-rvs-published-id___fr",
    path: "rvs/published/:id()",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/rvs/published/[id].vue")
  },
  {
    name: tripsqDyLEFuZCFMeta?.name,
    path: "trips",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/trips.vue"),
    children: [
  {
    name: "dashboard-trips-completed___fr",
    path: "completed",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/trips/completed.vue")
  },
  {
    name: "dashboard-trips___fr",
    path: "",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/trips/index.vue")
  },
  {
    name: "dashboard-trips-pending___fr",
    path: "pending",
    component: () => import("/azp/_work/1/s/src/pages/dashboard/trips/pending.vue")
  }
]
  }
]
  },
  {
    name: "experiments___en___default",
    path: "/experiments",
    meta: experimentst5Bhy7hZdDMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/experiments.vue")
  },
  {
    name: "experiments___en",
    path: "/en/experiments",
    meta: experimentst5Bhy7hZdDMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/experiments.vue")
  },
  {
    name: "experiments___fr",
    path: "/fr/experiments",
    meta: experimentst5Bhy7hZdDMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/experiments.vue")
  },
  {
    name: "how-it-works___en___default",
    path: "/how-it-works",
    component: () => import("/azp/_work/1/s/src/pages/how-it-works.vue")
  },
  {
    name: "how-it-works___en",
    path: "/en/how-it-works",
    component: () => import("/azp/_work/1/s/src/pages/how-it-works.vue")
  },
  {
    name: "how-it-works___fr",
    path: "/fr/comment-ca-fonctionne",
    component: () => import("/azp/_work/1/s/src/pages/how-it-works.vue")
  },
  {
    name: "index___en___default",
    path: "/",
    component: () => import("/azp/_work/1/s/src/pages/index.vue"),
    children: [
  {
    name: "index-logout___en___default",
    path: "/logout",
    component: () => import("/azp/_work/1/s/src/pages/index/logout.vue")
  },
  {
    name: "index-signin___en___default",
    path: "/signin",
    meta: signincM9ESoargsMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/index/signin.vue")
  },
  {
    name: "index-signup___en___default",
    path: "/signup",
    meta: signupnebmhd58jwMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/index/signup.vue")
  }
]
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/azp/_work/1/s/src/pages/index.vue"),
    children: [
  {
    name: "index-logout___en",
    path: "logout",
    component: () => import("/azp/_work/1/s/src/pages/index/logout.vue")
  },
  {
    name: "index-signin___en",
    path: "signin",
    meta: signincM9ESoargsMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/index/signin.vue")
  },
  {
    name: "index-signup___en",
    path: "signup",
    meta: signupnebmhd58jwMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/index/signup.vue")
  }
]
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/azp/_work/1/s/src/pages/index.vue"),
    children: [
  {
    name: "index-logout___fr",
    path: "deconnexion",
    component: () => import("/azp/_work/1/s/src/pages/index/logout.vue")
  },
  {
    name: "index-signin___fr",
    path: "connexion",
    meta: signincM9ESoargsMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/index/signin.vue")
  },
  {
    name: "index-signup___fr",
    path: "inscription",
    meta: signupnebmhd58jwMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/index/signup.vue")
  }
]
  },
  {
    name: "insurance-and-protection___en___default",
    path: "/insurance-and-protection",
    component: () => import("/azp/_work/1/s/src/pages/insurance-and-protection.vue")
  },
  {
    name: "insurance-and-protection___en",
    path: "/en/insurance-and-protection",
    component: () => import("/azp/_work/1/s/src/pages/insurance-and-protection.vue")
  },
  {
    name: "insurance-and-protection___fr",
    path: "/fr/assurances-et-protection",
    component: () => import("/azp/_work/1/s/src/pages/insurance-and-protection.vue")
  },
  {
    name: "owner___en___default",
    path: "/owner",
    meta: ownerfejiIrXDYtMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/owner.vue")
  },
  {
    name: "owner___en",
    path: "/en/owner",
    meta: ownerfejiIrXDYtMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/owner.vue")
  },
  {
    name: "owner___fr",
    path: "/fr/proprietaire",
    meta: ownerfejiIrXDYtMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/owner.vue")
  },
  {
    name: "privacy-policy___en___default",
    path: "/privacy-policy",
    component: () => import("/azp/_work/1/s/src/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___en",
    path: "/en/privacy-policy",
    component: () => import("/azp/_work/1/s/src/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___fr",
    path: "/fr/politique-de-confidentialite",
    component: () => import("/azp/_work/1/s/src/pages/privacy-policy.vue")
  },
  {
    name: "profile-id___en___default",
    path: "/profile/:id()",
    meta: _91id_93eNFzSjT0qdMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/profile/[id].vue")
  },
  {
    name: "profile-id___en",
    path: "/en/profile/:id()",
    meta: _91id_93eNFzSjT0qdMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/profile/[id].vue")
  },
  {
    name: "profile-id___fr",
    path: "/fr/profil/:id()",
    meta: _91id_93eNFzSjT0qdMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/profile/[id].vue")
  },
  {
    name: "recovery-password-code___en___default",
    path: "/recovery-password/:code()",
    meta: _91code_93EF3LjJL1R2Meta || {},
    component: () => import("/azp/_work/1/s/src/pages/recovery-password/[code].vue")
  },
  {
    name: "recovery-password-code___en",
    path: "/en/recovery-password/:code()",
    meta: _91code_93EF3LjJL1R2Meta || {},
    component: () => import("/azp/_work/1/s/src/pages/recovery-password/[code].vue")
  },
  {
    name: "recovery-password-code___fr",
    path: "/fr/recovery-password/:code()",
    meta: _91code_93EF3LjJL1R2Meta || {},
    component: () => import("/azp/_work/1/s/src/pages/recovery-password/[code].vue")
  },
  {
    name: "recovery-password___en___default",
    path: "/recovery-password",
    meta: indexf9oczNIiUqMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/recovery-password/index.vue")
  },
  {
    name: "recovery-password___en",
    path: "/en/recovery-password",
    meta: indexf9oczNIiUqMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/recovery-password/index.vue")
  },
  {
    name: "recovery-password___fr",
    path: "/fr/recovery-password",
    meta: indexf9oczNIiUqMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/recovery-password/index.vue")
  },
  {
    name: "reviews___en___default",
    path: "/reviews",
    component: () => import("/azp/_work/1/s/src/pages/reviews.vue")
  },
  {
    name: "reviews___en",
    path: "/en/reviews",
    component: () => import("/azp/_work/1/s/src/pages/reviews.vue")
  },
  {
    name: "reviews___fr",
    path: "/fr/evaluations",
    component: () => import("/azp/_work/1/s/src/pages/reviews.vue")
  },
  {
    name: "rv-delivery___en___default",
    path: "/rv-delivery",
    component: () => import("/azp/_work/1/s/src/pages/rv-delivery.vue")
  },
  {
    name: "rv-delivery___en",
    path: "/en/rv-delivery",
    component: () => import("/azp/_work/1/s/src/pages/rv-delivery.vue")
  },
  {
    name: "rv-delivery___fr",
    path: "/fr/livraison-vr",
    component: () => import("/azp/_work/1/s/src/pages/rv-delivery.vue")
  },
  {
    name: "rv-rental___en___default",
    path: "/rv-rental/:alias()",
    meta: indexUqnvKPGUwXMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/rv-rental/index.vue")
  },
  {
    name: "rv-rental___en",
    path: "/en/rv-rental/:alias()",
    meta: indexUqnvKPGUwXMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/rv-rental/index.vue")
  },
  {
    name: "rv-rental___fr",
    path: "/fr/location-vr/:alias()",
    meta: indexUqnvKPGUwXMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/rv-rental/index.vue")
  },
  {
    name: "rv-rental-request-to-book-alias___en___default",
    path: "/rv-rental/request-to-book/:alias()",
    meta: _91alias_93rgbP8kzgDIMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/rv-rental/request-to-book/[alias].vue"),
    children: [
  {
    name: "rv-rental-request-to-book-alias-additionals___en___default",
    path: "additionals",
    component: () => import("/azp/_work/1/s/src/pages/rv-rental/request-to-book/[alias]/additionals.vue")
  }
]
  },
  {
    name: "rv-rental-request-to-book-alias___en",
    path: "/en/rv-rental/request-to-book/:alias()",
    meta: _91alias_93rgbP8kzgDIMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/rv-rental/request-to-book/[alias].vue"),
    children: [
  {
    name: "rv-rental-request-to-book-alias-additionals___en",
    path: "additionals",
    component: () => import("/azp/_work/1/s/src/pages/rv-rental/request-to-book/[alias]/additionals.vue")
  }
]
  },
  {
    name: "rv-rental-request-to-book-alias___fr",
    path: "/fr/location-vr/request-to-book/:alias()",
    meta: _91alias_93rgbP8kzgDIMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/rv-rental/request-to-book/[alias].vue"),
    children: [
  {
    name: "rv-rental-request-to-book-alias-additionals___fr",
    path: "additionals",
    component: () => import("/azp/_work/1/s/src/pages/rv-rental/request-to-book/[alias]/additionals.vue")
  }
]
  },
  {
    name: "rv-rentals-keyword-campground-campgroundSlug___en___default",
    path: "/rv-rentals/:keyword?/campground/:campgroundSlug()",
    meta: _91campgroundSlug_93HLlZqkTIiKMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/rv-rentals/[[keyword]]/campground/[campgroundSlug].vue")
  },
  {
    name: "rv-rentals-keyword-campground-campgroundSlug___en",
    path: "/en/rv-rentals/:keyword?/campground/:campgroundSlug()",
    meta: _91campgroundSlug_93HLlZqkTIiKMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/rv-rentals/[[keyword]]/campground/[campgroundSlug].vue")
  },
  {
    name: "rv-rentals-keyword-campground-campgroundSlug___fr",
    path: "/fr/locations-vr/:keyword?/camping/:campgroundSlug()",
    meta: _91campgroundSlug_93HLlZqkTIiKMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/rv-rentals/[[keyword]]/campground/[campgroundSlug].vue")
  },
  {
    name: "rv-rentals-keyword-destination-destinationPath___en___default",
    path: "/rv-rentals/:keyword?/destination/:destinationPath(.*)*",
    meta: _91_46_46_46destinationPath_93s0eY6wiJLiMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/rv-rentals/[[keyword]]/destination/[...destinationPath].vue")
  },
  {
    name: "rv-rentals-keyword-destination-destinationPath___en",
    path: "/en/rv-rentals/:keyword?/destination/:destinationPath(.*)*",
    meta: _91_46_46_46destinationPath_93s0eY6wiJLiMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/rv-rentals/[[keyword]]/destination/[...destinationPath].vue")
  },
  {
    name: "rv-rentals-keyword-destination-destinationPath___fr",
    path: "/fr/locations-vr/:keyword?/destination/:destinationPath(.*)*",
    meta: _91_46_46_46destinationPath_93s0eY6wiJLiMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/rv-rentals/[[keyword]]/destination/[...destinationPath].vue")
  },
  {
    name: "rv-rentals-keyword___en___default",
    path: "/rv-rentals/:keyword()",
    meta: indexPHDROACDz0Meta || {},
    component: () => import("/azp/_work/1/s/src/pages/rv-rentals/[[keyword]]/index.vue")
  },
  {
    name: "rv-rentals-keyword___en",
    path: "/en/rv-rentals/:keyword()",
    meta: indexPHDROACDz0Meta || {},
    component: () => import("/azp/_work/1/s/src/pages/rv-rentals/[[keyword]]/index.vue")
  },
  {
    name: "rv-rentals-keyword___fr",
    path: "/fr/locations-vr/:keyword()",
    meta: indexPHDROACDz0Meta || {},
    component: () => import("/azp/_work/1/s/src/pages/rv-rentals/[[keyword]]/index.vue")
  },
  {
    name: "rv-rentals___en___default",
    path: "/rv-rentals",
    component: () => import("/azp/_work/1/s/src/pages/rv-rentals/index.vue")
  },
  {
    name: "rv-rentals___en",
    path: "/en/rv-rentals",
    component: () => import("/azp/_work/1/s/src/pages/rv-rentals/index.vue")
  },
  {
    name: "rv-rentals___fr",
    path: "/fr/locations-vr",
    component: () => import("/azp/_work/1/s/src/pages/rv-rentals/index.vue")
  },
  {
    name: "rv-search___en___default",
    path: "/rv-search",
    meta: rv_45searchj1OvdynQ19Meta || {},
    component: () => import("/azp/_work/1/s/src/pages/rv-search.vue")
  },
  {
    name: "rv-search___en",
    path: "/en/rv-search",
    meta: rv_45searchj1OvdynQ19Meta || {},
    component: () => import("/azp/_work/1/s/src/pages/rv-search.vue")
  },
  {
    name: "rv-search___fr",
    path: "/fr/recherche-vr",
    meta: rv_45searchj1OvdynQ19Meta || {},
    component: () => import("/azp/_work/1/s/src/pages/rv-search.vue")
  },
  {
    name: "service-fees___en___default",
    path: "/service-fees",
    component: () => import("/azp/_work/1/s/src/pages/service-fees.vue")
  },
  {
    name: "service-fees___en",
    path: "/en/service-fees",
    component: () => import("/azp/_work/1/s/src/pages/service-fees.vue")
  },
  {
    name: "service-fees___fr",
    path: "/fr/frais-de-service",
    component: () => import("/azp/_work/1/s/src/pages/service-fees.vue")
  },
  {
    name: "share-your-trip___en___default",
    path: "/share-your-trip",
    component: () => import("/azp/_work/1/s/src/pages/share-your-trip.vue")
  },
  {
    name: "share-your-trip___en",
    path: "/en/share-your-trip",
    component: () => import("/azp/_work/1/s/src/pages/share-your-trip.vue")
  },
  {
    name: "share-your-trip___fr",
    path: "/fr/partagez-votre-voyage",
    component: () => import("/azp/_work/1/s/src/pages/share-your-trip.vue")
  },
  {
    name: "superhost___en___default",
    path: "/superhost",
    meta: superhost0oG4Gwj16KMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/superhost.vue")
  },
  {
    name: "superhost___en",
    path: "/en/superhost",
    meta: superhost0oG4Gwj16KMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/superhost.vue")
  },
  {
    name: "superhost___fr",
    path: "/fr/superhotes",
    meta: superhost0oG4Gwj16KMeta || {},
    component: () => import("/azp/_work/1/s/src/pages/superhost.vue")
  },
  {
    name: "terms-service___en___default",
    path: "/terms-service",
    component: () => import("/azp/_work/1/s/src/pages/terms-service.vue")
  },
  {
    name: "terms-service___en",
    path: "/en/terms-service",
    component: () => import("/azp/_work/1/s/src/pages/terms-service.vue")
  },
  {
    name: "terms-service___fr",
    path: "/fr/termes-utilisation",
    component: () => import("/azp/_work/1/s/src/pages/terms-service.vue")
  },
  {
    name: "trust-and-safety___en___default",
    path: "/trust-and-safety",
    component: () => import("/azp/_work/1/s/src/pages/trust-and-safety.vue")
  },
  {
    name: "trust-and-safety___en",
    path: "/en/trust-and-safety",
    component: () => import("/azp/_work/1/s/src/pages/trust-and-safety.vue")
  },
  {
    name: "trust-and-safety___fr",
    path: "/fr/confiance-et-securite",
    component: () => import("/azp/_work/1/s/src/pages/trust-and-safety.vue")
  }
]