import type { GlobalModal, Modal } from '~/types'

/* v8 ignore start */
export const GLOBAL_MODAL_COMPONENTS = {
  BookingPaymentDetailsModal: () => import('~/components/booking/payment-details-modal/payment-details-modal.vue'),
  BookingPayoutDetailsModal: () => import('~/components/booking/payout-details-modal/payout-details-modal.vue'),
  BookingReviewsReportModal: () => import('~/components/booking/reviews/report-modal.vue'),
  ChangeRequestErrorModal: () => import('~/components/booking/edit/change-request-error-modal.vue'),
  DownloadAppModal: () => import('~/components/booking/download-app-modal/index.vue'),
} as const
export type GLOBAL_MODAL_COMPONENTS = typeof GLOBAL_MODAL_COMPONENTS[keyof typeof GLOBAL_MODAL_COMPONENTS]
/* v8 ignore stop */

/**
 * This composable provides methods to interact with the global modal queue.
 *
 * ### Usage
 * - **Local modals:** Use the computed property returned by `createVisibilityComputed()` directly within a modal's `v-model`.
 *   This allows you to have individual modal visibility locally, the modal queue will be managed automatically.
 *
 * - **Global modals:** Use `queueModal()` to add a modal to the global queue, optionally with priority, for globally managed modal stacking and display.
 *
 * ### Local Modal Example
 * ```html
 * <template>
 *   <ZModal v-model="isVisible">
 *     <!-- Modal content here -->
 *   </ZModal>
 * </template>
 * <script setup lang="ts">
 * const { createVisibilityComputed } = useModals()
 * const isVisible = createVisibilityComputed()
 * </script>
 * ```
 *
 * ### Global Modal Example
 * ```html
 * <script setup lang="ts">
 * const { queueModal } = useModals()
 * queueModal({ globalModal: { tag: GLOBAL_MODAL_COMPONENTS.BookingDownloadAppModal}})
 * </script>
 * ```
 */
export default function useModals() {
  const modalsQueue = useModalsQueue()

  const currentModalID = computed(() => modalsQueue.value.queue[modalsQueue.value.index]?.id)
  const nextModal = computed(() => modalsQueue.value.queue[modalsQueue.value.index + 1])

  /**
   *
   * @param hasPriority - If true, the modal will be added to the front of the queue
   * @param modalID - The ID of the modal to be added to the queue
   * @returns WritableComputedRef<boolean, boolean> to be used in the modal's v-model
   */
  function createVisibilityComputed({ hasPriority, modalID }: { hasPriority?: boolean, modalID?: string } = {}) {
    const id = modalID ?? generateUuid()
    return computed({
      get: () => id === currentModalID.value,
      set: (value) => {
        if (value) {
          queueModal({ hasPriority, modalID: id })
        }
        else {
          dequeueModal(id)
        }
      },
    })
  }

  /**
   *
   * @param hasPriority - If true, the modal will be added to the front of the queue
   * @param modalID - The ID of the modal to be added to the queue
   * @param globalModal - The global modal object to be added to the queue
   */
  function queueModal({ hasPriority, modalID, globalModal }: { hasPriority?: boolean, modalID?: string, globalModal?: GlobalModal }) {
    const modal = {
      id: modalID ?? generateUuid(),
      ...globalModal ?? {},
    }

    if (hasPriority) {
      addPriorityModalToQueue([modal].flat())
    }
    else {
      addModalToQueue([modal].flat())
    }

    dequeueDuplicateModals(modal.id)

    if (!modalsQueue.value.isVisible) {
      showNextModal()
    }
  }

  function showNextModal() {
    if (!nextModal.value) {
      hideModal()
      clearQueue()
      return
    }

    moveForward()
    showModal()
  }

  function dequeueModal(modalID: string) {
    if (currentModalID.value === modalID) {
      moveBackward()
    }
    modalsQueue.value.queue = modalsQueue.value.queue.filter((m) => m.id !== modalID)

    showNextModal()
  }

  function dequeueDuplicateModals(modalID: string) {
    const firstIndex = modalsQueue.value.queue.findIndex((m) => m.id === modalID)
    modalsQueue.value.queue = modalsQueue.value.queue.filter((item, pos) => {
      return item.id !== modalID || pos === firstIndex
    })
  }

  function navigationPurge() {
    purge()
    showNextModal()
  }

  function moveForward() {
    modalsQueue.value.index++
  }

  function moveBackward() {
    modalsQueue.value.index--
  }

  function addPriorityModalToQueue(modals: Modal[]) {
    modalsQueue.value.queue.unshift(...modals)
  }

  function addModalToQueue(modals: Modal[]) {
    modalsQueue.value.queue.push(...modals)
  }

  function showModal() {
    modalsQueue.value.isVisible = true
  }

  function hideModal() {
    modalsQueue.value.isVisible = false
  }

  function purge() {
    modalsQueue.value.queue = modalsQueue.value.queue.filter((modal) => typeof modal === 'object' && modal.persist)
    modalsQueue.value.index = -1
    modalsQueue.value.isVisible = false
  }

  function clearQueue() {
    modalsQueue.value.queue = []
    modalsQueue.value.index = -1
  }

  return {
    modalsQueue,

    queueModal,
    createVisibilityComputed,
    showNextModal,
    navigationPurge,
  }
}
