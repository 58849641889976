import type { COUNTRY_SHORTS } from '~/constants'
import type { Nullable } from '~/types'
import type { FinancialCommon } from '~/types/financial'

export function getFinancial(financial?: Nullable<FinancialCommon>) {
  /**
   * Basic Financial properties.
   * Always in the format of `financial[PropertyName]`.
   * Defaults are dependent on the schema and added manually.
   */
  const financialAddOns = financial?.AddOns ?? []
  const financialAmountDue = financial?.AmountDue ?? 0
  const financialBookingId = financial?.BookingId
  const financialCountry = financial?.Country as COUNTRY_SHORTS
  const financialDeductibleAmount = financial?.DeductibleAmount ?? 0
  const financialDeductibleAmountBasic = financial?.DeductibleAmountBasic ?? 0
  const financialDeductibleAmountPremium = financial?.DeductibleAmountPremium ?? 0
  const financialDeductibleAmountStandard = financial?.DeductibleAmountStandard ?? 0
  const financialDeductibleAmountStationary = financial?.DeductibleAmountStationary ?? 0
  const financialDepositAmount = financial?.DepositAmount ?? 0
  const financialDiscount = financial?.Discount ?? 0
  const financialInsuranceProtectionLevel = financial?.InsuranceProtectionLevel ?? undefined
  const financialNightlyInsuranceDiscountRateStationary = financial?.NightlyInsuranceDiscountRateStationary ?? 0
  const financialNightlyInsuranceRateBasic = financial?.NightlyInsuranceRateBasic ?? 0
  const financialNightlyInsuranceRatePremium = financial?.NightlyInsuranceRatePremium ?? 0
  const financialNightlyInsuranceRateStandard = financial?.NightlyInsuranceRateStandard ?? 0
  const financialNightlyInsuranceRateStationary = financial?.NightlyInsuranceRateStationary ?? 0
  const financialNightlyRoadsideBasicRate = financial?.NightlyRoadsideBasicRate ?? 0
  const financialNightlyRoadsidePremiumRate = financial?.NightlyRoadsidePremiumRate ?? 0
  const financialNightlyRoadsideStandardRate = financial?.NightlyRoadsideStandardRate ?? 0
  const financialNightlyRoadsideStationaryRate = financial?.NightlyRoadsideStationaryRate ?? 0
  const financialOwnerPayoutTotal = financial?.OwnerPayoutTotal ?? 0
  const financialOwnerReferralCredits = financial?.OwnerReferralCredits ?? 0
  const financialOwnerSalesTaxAmounts = financial?.OwnerSalesTaxAmounts ?? []
  const financialOwnerTaxAmounts = financial?.OwnerTaxAmounts ?? []
  const financialOwnerTotal = financial?.OwnerTotal ?? 0
  const financialPayLater = financial?.PayLater ?? 0
  const financialPayLaterOn = financial?.PayLaterOn
  const financialPayments = financial?.Payments ?? []
  const financialPayToReserve = financial?.PayToReserve ?? 0
  const financialPendingRenterRefund = financial?.PendingRenterRefund ?? false
  const financialRenterDiscountedTotal = financial?.RenterDiscountedTotal ?? 0
  const financialRenterOutstandingBalance = financial?.RenterOutstandingBalance ?? 0
  const financialRenterTaxAmounts = financial?.RenterTaxAmounts ?? []
  const financialRenterTotal = financial?.RenterTotal ?? 0
  const financialSecurityDepositAmount = financial?.SecurityDepositAmount ?? 0
  const financialSecurityDepositBalance = financial?.SecurityDepositBalance ?? 0
  const financialSecurityDepositFailed = financial?.SecurityDepositFailed ?? undefined
  const financialSecurityDepositOn = financial?.SecurityDepositOn ?? undefined
  const financialSecurityDepositTaken = financial?.SecurityDepositTaken ?? undefined
  const financialStatus = financial?.Status
  const financialTotalAddOns = financial?.TotalAddOns ?? 0
  const financialTotalCleanings = financial?.TotalCleanings ?? 0
  const financialTotalCloseouts = financial?.TotalCloseouts ?? 0
  const financialTotalDamages = financial?.TotalDamages ?? 0
  const financialTotalDelivery = financial?.TotalDelivery ?? 0
  const financialTotalDeliveryFee = financial?.TotalDeliveryFee ?? 0
  const financialTotalIncomeProtection = financial?.TotalIncomeProtection ?? 0
  const financialTotalIncomeProtectionPayout = financial?.TotalIncomeProtectionPayout ?? 0
  const financialTotalInsurances = financial?.TotalInsurances ?? 0
  const financialTotalNights = financial?.TotalNights ?? 0
  const financialTotalOwnerSalesTaxes = financial?.TotalOwnerSalesTaxes ?? 0
  const financialTotalOwnerServiceFee = financial?.TotalOwnerServiceFee ?? 0
  const financialTotalOwnerServiceFeeWithoutDelivery = financial?.TotalOwnerServiceFeeWithoutDelivery ?? 0
  const financialTotalOwnerTaxes = financial?.TotalOwnerTaxes ?? 0
  const financialTotalPaid = financial?.TotalPaid ?? 0
  const financialTotalPayoutAmounts = financial?.TotalPayoutAmounts ?? 0
  const financialTotalPreDiscountRentalAmount = financial?.TotalPreDiscountRentalAmount ?? 0
  const financialTotalRentalAmount = financial?.TotalRentalAmount ?? 0
  const financialTotalRentalPaid = financial?.TotalRentalPaid ?? 0
  const financialTotalRenterServiceFee = financial?.TotalRenterServiceFee ?? 0
  const financialTotalRenterTaxes = financial?.TotalRenterTaxes ?? 0
  const financialTotalReversiblePayoutAmount = financial?.TotalReversiblePayoutAmount ?? 0
  const financialTotalRoadsides = financial?.TotalRoadsides ?? 0
  const financialTotalSecurityDepositPaymentReductions = financial?.TotalSecurityDepositPaymentReductions ?? 0
  const financialTotalSecurityDepositPayments = financial?.TotalSecurityDepositPayments ?? 0
  const financialTotalSecurityDeposits = financial?.TotalSecurityDeposits ?? 0
  const financialTotalUSTaxes = financial?.TotalUSTaxes ?? 0
  const financialUSTaxAmounts = financial?.USTaxAmounts ?? []

  // Discounts
  const financialDiscountsMidweekdays = financial?.Discounts?.Midweekdays
  const financialDiscountsMonthly = financial?.Discounts?.Monthly
  const financialDiscountsWeekly = financial?.Discounts?.Weekly

  // Nightly
  const financialNightlyBase = financial?.Nightly?.Base ?? 0
  const financialNightlyDiscounted = financial?.Nightly?.Discounted ?? 0

  // Credits
  const financialCreditsApplied = financial?.CreditsApplied ?? 0
  const financialCreditsToBeApplied = financial?.CreditsToBeApplied ?? 0
  const financialCreditsToBeAppliedLater = financial?.CreditsToBeAppliedLater ?? 0
  const financialTotalCreditsToBeApplied = financial?.TotalCreditsToBeApplied ?? 0

  // Promo Code
  const financialPromoCode = financial?.PromoCode ?? null
  const financialTotalPromotionalDiscountAmount = financial?.TotalPromotionalDiscountAmount ?? 0

  /**
   * Basic `is` checks.
   * Always in the format of `is[PropertyName]`.
   */
  const financialIsAwaitingOwnerApproval = financialStatus === 'AwaitingOwnerApproval'
  const financialHasStationaryDiscount = financialNightlyInsuranceDiscountRateStationary > 0

  /**
   * Advanced properties that depend on more than just the base properties.
   * Always in the format of `financial[CustomPropertyName]`.
   */
  const financialCurrency = lookupCountryCodeToCurrencyCode(financialCountry)
  const financialRvAddOns = financialAddOns.filter((addon) => addon.AddOnType === 'RVAddOn')
  const financialRvezyAmount = Number(((financialTotalPaid) * 1.1).toFixed(2))
  const financialTotalDays = financialTotalNights + 1
  const financialTotalProtection = financialTotalInsurances + financialTotalRoadsides
  const financialStationaryDiscountPercentage = decimalToPercentage(financialNightlyInsuranceDiscountRateStationary)

  return {
    financialAddOns,
    financialAmountDue,
    financialBookingId,
    financialCountry,
    financialDeductibleAmount,
    financialDeductibleAmountBasic,
    financialDeductibleAmountPremium,
    financialDeductibleAmountStandard,
    financialDeductibleAmountStationary,
    financialDepositAmount,
    financialDiscount,
    financialHasStationaryDiscount,
    financialInsuranceProtectionLevel,
    financialNightlyInsuranceDiscountRateStationary,
    financialNightlyInsuranceRateBasic,
    financialNightlyInsuranceRatePremium,
    financialNightlyInsuranceRateStandard,
    financialNightlyInsuranceRateStationary,
    financialNightlyRoadsideBasicRate,
    financialNightlyRoadsidePremiumRate,
    financialNightlyRoadsideStandardRate,
    financialNightlyRoadsideStationaryRate,
    financialOwnerPayoutTotal,
    financialOwnerReferralCredits,
    financialOwnerSalesTaxAmounts,
    financialOwnerTaxAmounts,
    financialOwnerTotal,
    financialPayLater,
    financialPayLaterOn,
    financialPayments,
    financialPayToReserve,
    financialPendingRenterRefund,
    financialRenterDiscountedTotal,
    financialRenterOutstandingBalance,
    financialRenterTaxAmounts,
    financialRenterTotal,
    financialSecurityDepositAmount,
    financialSecurityDepositBalance,
    financialSecurityDepositFailed,
    financialSecurityDepositOn,
    financialSecurityDepositTaken,
    financialStationaryDiscountPercentage,
    financialStatus,
    financialTotalAddOns,
    financialTotalCleanings,
    financialTotalCloseouts,
    financialTotalDamages,
    financialTotalDelivery,
    financialTotalDeliveryFee,
    financialTotalIncomeProtection,
    financialTotalIncomeProtectionPayout,
    financialTotalInsurances,
    financialTotalNights,
    financialTotalOwnerSalesTaxes,
    financialTotalOwnerServiceFee,
    financialTotalOwnerServiceFeeWithoutDelivery,
    financialTotalOwnerTaxes,
    financialTotalPaid,
    financialTotalPayoutAmounts,
    financialTotalPreDiscountRentalAmount,
    financialTotalRentalAmount,
    financialTotalRentalPaid,
    financialTotalRenterServiceFee,
    financialTotalRenterTaxes,
    financialTotalReversiblePayoutAmount,
    financialTotalRoadsides,
    financialTotalSecurityDepositPaymentReductions,
    financialTotalSecurityDepositPayments,
    financialTotalSecurityDeposits,
    financialTotalUSTaxes,
    financialUSTaxAmounts,

    financialDiscountsMidweekdays,
    financialDiscountsMonthly,
    financialDiscountsWeekly,

    financialNightlyBase,
    financialNightlyDiscounted,

    financialCreditsToBeApplied,
    financialTotalCreditsToBeApplied,
    financialCreditsApplied,
    financialCreditsToBeAppliedLater,

    financialPromoCode,
    financialTotalPromotionalDiscountAmount,

    financialIsAwaitingOwnerApproval,

    financialCurrency,
    financialRvAddOns,
    financialRvezyAmount,
    financialTotalDays,
    financialTotalProtection,
  }
}
