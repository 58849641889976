export const ONE_SECOND_IN_MS = 1000
export const ONE_HOUR_IN_MINUTES = 60
export const FIVE_SECONDS_IN_MS = ONE_SECOND_IN_MS * 5
export const FIFTEEN_SECONDS_IN_MS = ONE_SECOND_IN_MS * 15
export const THIRTY_SECONDS_IN_MS = ONE_SECOND_IN_MS * 30
export const NINETY_SECONDS_IN_MS = ONE_SECOND_IN_MS * 90
export const ONE_MINUTE_IN_MS = ONE_SECOND_IN_MS * 60
export const FIFTEEN_MINUTES_IN_MS = ONE_MINUTE_IN_MS * 15
export const ONE_HOUR_IN_SECONDS = ONE_HOUR_IN_MINUTES * 60
export const ONE_DAY_IN_SECONDS = ONE_HOUR_IN_SECONDS * 24
export const ONE_DAY_IN_MINUTES = ONE_HOUR_IN_MINUTES * 24
export const ONE_WEEK_IN_SECONDS = ONE_DAY_IN_SECONDS * 7
export const TWO_WEEKS_IN_SECONDS = ONE_DAY_IN_SECONDS * 14
export const THIRTY_DAYS_IN_SECONDS = ONE_DAY_IN_SECONDS * 30
export const SIXTY_DAYS_IN_SECONDS = ONE_DAY_IN_SECONDS * 60
export const ONE_YEAR_IN_SECONDS = ONE_DAY_IN_SECONDS * 365
export const SIXTEEN_YEARS_IN_SECONDS = ONE_YEAR_IN_SECONDS * 16
