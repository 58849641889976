import type { Nullable } from '~/types'
import type { BookingChangeRequestResponse } from '~/types/change-request'

export function getChangeRequest(changeRequest?: Nullable<BookingChangeRequestResponse>) {
  /**
   * Basic Change Request properties.
   * Always in the format of `changeRequest[PropertyName]`.
   * Defaults are dependent on the schema and added manually.
   */

  const changeRequestAdults = changeRequest?.Adults ?? 0
  const changeRequestChildren = changeRequest?.Children ?? 0
  const changeRequestDestination = changeRequest?.Destination ?? ''
  const changeRequestDeliveryDistance = changeRequest?.DeliveryDistance ?? 0
  const changeRequestEndDate = changeRequest?.EndDate ?? undefined
  const changeRequestPets = Boolean(changeRequest?.Pets)
  const changeRequestRvUsage = changeRequest?.RvUsage
  const changeRequestStartDate = changeRequest?.StartDate ?? undefined

  const changeRequestItemsAddAddOns = changeRequest?.ChangeRequestItems?.AddAddOns ?? []
  const changeRequestItemsRemoveAddOns = changeRequest?.ChangeRequestItems?.RemoveAddOns ?? []

  // Advanced propeties.
  const changeRequestNumberOfNights = dates.utc(changeRequestEndDate).diff(dates.utc(changeRequestStartDate), 'days')

  return {
    changeRequestAdults,
    changeRequestChildren,
    changeRequestDestination,
    changeRequestDeliveryDistance,
    changeRequestEndDate,
    changeRequestPets,
    changeRequestRvUsage,
    changeRequestStartDate,

    changeRequestItemsAddAddOns,
    changeRequestItemsRemoveAddOns,

    changeRequestNumberOfNights,
  }
}
