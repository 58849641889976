<template>
  <Html :data-n-head-ssr="isHydrating">
    <LazyAppMaintenance v-if="maintenanceMode" />
    <NuxtLayout v-else>
      <template
        v-if="shouldShowUpgradeBrowser"
        #pre-header
      >
        <LazyAppUpgradeBrowser />
      </template>
      <NuxtPage />
      <LazyAppAuthModal v-if="!isLoggedIn && authenticationModalShown" />
    </NuxtLayout>

    <ZToastQueue />
    <Modals />
    
  </Html>
</template>

<script setup lang="ts">
import '@stripe/stripe-js'
import { trackStartup } from '~/lib/experiments/utils'
import type { SegmentAnalyticsType } from '~/types/segment'
import { COOKIES } from '~/constants'

declare global {
  interface Window {
    dataLayer: Record<string, unknown>[]
    analytics: SegmentAnalyticsType
    localStorage: Storage
    clarity?: (...args: unknown[]) => void
  }
}

const { maintenanceMode } = useMaintenanceMode()
const browserUpgradeCookie = useCookie(COOKIES.upgradeBrowser)
const shouldShowUpgradeBrowser = computed(() => {
  return isRecognizedBrowser && (!isFullySupported && (!browserUpgradeCookie.value || browserUpgradeCookie.value === '0'))
})

const { locale } = useI18n()
watch(locale, (newLocale) => {
  dates.locale(newLocale)
}, {
  immediate: true,
})

/**
 * This is added in order to follow the same behaviour as Nuxt2.
 * It's used by the Automated tests to know when hydration is done.
 */
const { hydrationStatus } = useHydrationState()
const isHydrating = computed(() => {
  return hydrationStatus.value ? undefined : true
})

const { authenticationModalShown, isLoggedIn } = useWatchAuthState()
useInitApp()

/**
 * Initializes the app.
 */
function useInitApp() {
  const { t, te } = useI18n()
  const { routeBaseName } = useBaseName()

  const title = computed(() =>
    te(`pages.${routeBaseName.value}.metaTitle`) ? t(`pages.${routeBaseName.value}.metaTitle`) : routeBaseName.value,
  )

  const description = computed(() =>
    te(`pages.${routeBaseName.value}.metaDesc`) ? t(`pages.${routeBaseName.value}.metaDesc`) : '',
  )

  useSeoHeadTags({
    title: () => title.value,
    description: () => description.value,
  })

  onMounted(async () => {
    const { openSession } = await useBraze()
    const experiments = useExperiments()
    const { isWebView } = usePlatform()

    // Open a new Braze session
    openSession()

    // Track experiments
    trackStartup(experiments.value?.evaluated, isWebView.value)
  })
}

/**
 * Watches the user's auth state and triggers related functionality.
 */
function useWatchAuthState() {
  const runtimeConfig = useRuntimeConfig()
  const { trackUserAuthentication, trackUserDeauthentication } = useTracking()
  const { status, user, isLoggedIn, authenticationModalShown } = useAuthentication()
  const { getFavourites, clearFavourites } = useFavouriteRVs()
  const permissions = usePermissions()
  const { $experiment, $rentals } = useNuxtApp()
  const rtsessionIdCookie = useCookie(COOKIES.session)
  /**
   * Identifies the user in the analytics platforms.
   */
  async function identifyUser() {
    if (import.meta.client && user.value) {
      // Analytics tracking
      trackUserAuthentication(user.value, rtsessionIdCookie.value ?? '')

      // Braze tracking
      let userID = user.value.Id.toString()
      if (runtimeConfig.public.braze.userPrefix) {
        userID = `${runtimeConfig.public.braze.userPrefix}_${user.value.Id}`
      }

      const { setUser } = await useBraze()
      setUser(userID)
    }
  }

  function unidentifyUser() {
    if (import.meta.client) {
      // Analytics tracking
      trackUserDeauthentication()
    }
  }

  /**
   * Get permissions
   */
  async function getPermissions() {
    if (!isLoggedIn.value) {
      permissions.value = []
      return
    }

    const data = await $rentals('/api/account/me/permissions')

    permissions.value = data
  }

  /**
   * Watches the authentication status and fetches the user's list of RV or
   * removes it.
   */
  watch(
    status,
    async (newStatus, previousStatus) => {
      /**
       * User identification
       */
      if (newStatus === 'authenticated') {
        await identifyUser()

        // Get additional user data (getUserExtraInfo)
        await Promise.all([getFavourites(), getPermissions()])
      }
      else if (previousStatus && newStatus === 'unauthenticated') {
        unidentifyUser()

        clearFavourites()
      }

      if (newStatus !== 'loading') {
        /**
         * Refresh Experiments
         */
        if (previousStatus) {
          await $experiment.refreshExperiments()
        }
      }
    },
    {
      immediate: true,
    },
  )

  return { authenticationModalShown, isLoggedIn }
}

const { isRecognizedBrowser, isFullySupported } = await checkBrowserSupport(useRequestHeader('User-Agent'))
</script>
