import type { Nullable } from '~/types'
import type { GetListProfile } from '~/types/rental-api-aliases'

export default function useBookingUsers(newUsers?: Ref<Nullable<{ owner: Nullable<GetListProfile>, renter: Nullable<GetListProfile> }>>) {
  const users = useSelectedBookingUsers()

  if (newUsers !== undefined) {
    watch(newUsers, () => {
      users.value = newUsers.value
    }, {
      immediate: true,
    })
  }

  const { user } = useAuthentication()
  const userIsBookingOwner = computed(() => users.value?.owner?.Id === user.value?.Id)
  const userIsBookingRenter = computed(() => users.value?.renter?.Id === user.value?.Id)

  const userOwner = computed(() => getBookingUser(users.value?.owner))
  const userRenter = computed(() => getBookingUser(users.value?.renter))

  return {
    userMe: userIsBookingOwner.value ? userOwner : userRenter,
    userThem: userIsBookingOwner.value ? userRenter : userOwner,
    userOwner,
    userRenter,
    userIsBookingOwner,
    userIsBookingRenter,
  }
}
