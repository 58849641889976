import revive_payload_client_4sVQNw7RlN from "/azp/_work/1/s/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/azp/_work/1/s/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/azp/_work/1/s/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_jtc2qNDx4l from "/azp/_work/1/s/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/azp/_work/1/s/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/azp/_work/1/s/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/azp/_work/1/s/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/azp/_work/1/s/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/azp/_work/1/s/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/azp/_work/1/s/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import fontawesome_7xXmPH2btU from "/azp/_work/1/s/node_modules/@vesp/nuxt-fontawesome/dist/runtime/plugins/fontawesome.js";
import plugin_I4dbrL2rYz from "/azp/_work/1/s/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.js";
import i18n_Ug908EZNww from "/azp/_work/1/s/node_modules/nuxt-site-config/dist/runtime/app/plugins/i18n.js";
import plugin_ghbUAjaD3n from "/azp/_work/1/s/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_iGLjBbor9q from "/azp/_work/1/s/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import switch_locale_path_ssr_5csfIgkrBP from "/azp/_work/1/s/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_VrpP1JaYg8 from "/azp/_work/1/s/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_sq1MuCrqbC from "/azp/_work/1/s/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import _01_custom_fetchers_oDIgp9aGY7 from "/azp/_work/1/s/src/plugins/01.custom-fetchers.ts";
import cookies_U7piddfsy3 from "/azp/_work/1/s/src/plugins/cookies.ts";
import directives_zayG7cuA64 from "/azp/_work/1/s/src/plugins/directives.ts";
import error_handler_eQzajpVw3N from "/azp/_work/1/s/src/plugins/error-handler.ts";
import experiment_MiG2WaU4zp from "/azp/_work/1/s/src/plugins/experiment.ts";
import openFetch_QuQyGmZ2U9 from "/azp/_work/1/s/src/plugins/openFetch.ts";
import redirect_XsYUYbG8eo from "/azp/_work/1/s/src/plugins/redirect.ts";
import route_after_client_2O5aVCvg1d from "/azp/_work/1/s/src/plugins/route-after.client.ts";
import search_WRS7IwursB from "/azp/_work/1/s/src/plugins/search.ts";
import v_bind_once_2HpLhNPFTh from "/azp/_work/1/s/src/plugins/v-bind-once.ts";
import ssg_detect_3fHkBxLtv0 from "/azp/_work/1/s/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_jtc2qNDx4l,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  fontawesome_7xXmPH2btU,
  plugin_I4dbrL2rYz,
  i18n_Ug908EZNww,
  plugin_ghbUAjaD3n,
  plugin_iGLjBbor9q,
  switch_locale_path_ssr_5csfIgkrBP,
  route_locale_detect_VrpP1JaYg8,
  i18n_sq1MuCrqbC,
  _01_custom_fetchers_oDIgp9aGY7,
  cookies_U7piddfsy3,
  directives_zayG7cuA64,
  error_handler_eQzajpVw3N,
  experiment_MiG2WaU4zp,
  openFetch_QuQyGmZ2U9,
  redirect_XsYUYbG8eo,
  route_after_client_2O5aVCvg1d,
  search_WRS7IwursB,
  v_bind_once_2HpLhNPFTh,
  ssg_detect_3fHkBxLtv0
]