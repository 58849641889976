import { ROUTE_BASE_NAME } from '~/constants'
/**
 * Composable that returns the current route base name. Previously this was a
 * direct call in i18n, but since the Nuxt3 version of this module, we always
 * need to get the route and pass it into their composable. We use it in a lot
 * of places, so this abstraction makes sense.
 */
export default function useBaseName() {
  const route = useRoute()
  const getRouteBaseName = useRouteBaseName()
  const routeBaseName = computed(() => {
    return getRouteBaseName(route) || ''
  })

  const isHomePage = computed(() => {
    return routeBaseName.value.startsWith(ROUTE_BASE_NAME.index)
  })

  const isDashboardPage = computed(() => {
    return routeBaseName.value.startsWith(ROUTE_BASE_NAME.dashboard)
  })

  const isOwnerPage = computed(() => {
    return routeBaseName.value === ROUTE_BASE_NAME.owner
  })

  const isRvSearchPage = computed(() => {
    return routeBaseName.value === ROUTE_BASE_NAME.rv_search
  })

  const isRvRentalPage = computed(() => {
    return routeBaseName.value === ROUTE_BASE_NAME.rv_rental
  })

  const isRvRentalsPage = computed(() => {
    return routeBaseName.value === ROUTE_BASE_NAME.rv_rentals
  })

  const isRvRentalsOrChildPage = computed(() => {
    const rvRentalsPages: string[] = [ROUTE_BASE_NAME.rv_rentals, ROUTE_BASE_NAME.rv_rentals_keyword, ROUTE_BASE_NAME.rv_rentals_destination, ROUTE_BASE_NAME.rv_rentals_campground]
    return rvRentalsPages.includes(routeBaseName.value)
  })

  const isRequestToBookFlow = computed(() => {
    return isRequestToBookPage.value || isPaymentPage.value
  })

  const isRequestToBookPage = computed(() => {
    return routeBaseName.value.startsWith('rv-rental-request-to-book')
  })

  const isPaymentPage = computed(() => {
    return routeBaseName.value === ROUTE_BASE_NAME.payment
  })

  const isSuperHostPage = computed(() => {
    return routeBaseName.value === ROUTE_BASE_NAME.superhost
  })

  return {
    routeBaseName,

    isDashboardPage,
    isHomePage,
    isOwnerPage,
    isPaymentPage,
    isRequestToBookFlow,
    isRequestToBookPage,
    isRvRentalPage,
    isRvRentalsOrChildPage,
    isRvRentalsPage,
    isRvSearchPage,
    isSuperHostPage,
  }
}
