import type { Nullable } from '~/types'
import type { GetListProfile } from '~/types/rental-api-aliases'

export const getBookingUser = (user?: Nullable<GetListProfile>) => {
  const userAbout = user?.About ?? ''
  const userAvatar = user?.Avatar ?? ''
  const userCoverImage = user?.CoverImage ?? null
  const userCreatedAt = user?.CreatedAt ?? ''
  const userFirstName = user?.FirstName ?? ''
  const userId = user?.Id ?? 0
  const userIsSuperHostActive = user?.IsSuperHostActive ?? false
  const userIsVerified = user?.IsVerified ?? false
  const userLanguage = user?.Language ?? 'Any'
  const userLastName = user?.LastName ?? ''
  const userLocation = user?.Location ?? null
  const userName = user?.Name ?? ''

  // Advanced has checks
  const userHasCreatedAtDate = userCreatedAt !== ''

  return {
    userAbout,
    userAvatar,
    userCoverImage,
    userCreatedAt,
    userFirstName,
    userId,
    userIsSuperHostActive,
    userIsVerified,
    userLanguage,
    userLastName,
    userLocation,
    userName,

    userHasCreatedAtDate,
  }
}
