/**
 * This file is automatically generated. DO NOT MODIFY
 *
 * Generated By: scripts/generateEnums.js
 * Timestamp: Tue Jan 07 2025 08:59:19 GMT-0500 (Eastern Standard Time)
 */

import Enum from './enum.js'

/**
 * @enum {string}
 * @see {@link https://github.com/RVezy/rvezy-back-end/blob/master/RVezy.Common/CommonEnum/BookingPreferenceRequiredType.cs#L3}
 */
export class BookingPreferenceRequiredTypeEnumKey extends Enum {
  static Required = 'Required'
  static NotRequired = 'NotRequired'
  static Negotiable = 'Negotiable'
}

/**
 * @enum {string}
 * @see {@link https://github.com/RVezy/rvezy-back-end/blob/master/RVezy.Common/CommonEnum/BookingPreferencesType.cs#L3}
 */
export class BookingPreferencesTypeEnumKey extends Enum {
  static AllowOnlyExperiencedSimilarRV = 'AllowOnlyExperiencedSimilarRV'
  static AllowFestivalEventsParticipation = 'AllowFestivalEventsParticipation'
  static CheckInTime = 'CheckInTime'
  static CheckOutTime = 'CheckOutTime'
  static AllowCrossBorderTravel = 'AllowCrossBorderTravel'
  static BookingSeason = 'BookingSeason'
}

/**
 * @enum {string}
 * @see {@link https://github.com/RVezy/rvezy-back-end/blob/master/RVezy.Common/CommonEnum/BookingReviewCategoryType.cs#L3}
 */
export class BookingReviewCategoryTypeEnumKey extends Enum {
  static Unknown = 'Unknown'
  static Value = 'Value'
  static ListingAccuracy = 'ListingAccuracy'
  static Cleanliness = 'Cleanliness'
  static Communication = 'Communication'
}

/**
 * @enum {number}
 * @see {@link https://github.com/RVezy/rvezy-back-end/blob/master/RVezy.Common/Models/BookingStageEnum.cs#L5}
 */
export class BookingStageEnum extends Enum {
  static AwaitingOwnerApproval = 0
  static AwaitingPayment = 1
  static DeclinedByTheOwner = 2
  static DeclinedByTheRenter = 3
  static DepositTaken = 4
  static FullPaymentReceived = 6
  static SecurityDepositTaken = 7
  static PendingPayout = 11
  static Completed = 12
  static CanceledByTheRenter = 13
  static CanceledByTheOwner = 14
  static PendingInsuranceClaim = 15
  static ExpiredRequest = 16
  static ExpiredNoPayment = 17
  static EnquiryRequest = 18
  static ExpiredByAdmin = 19
  static TripStarted = 20
  static Invoice = 21
  static InstantBooking = 22
  static ExpiredInstantBook = 23
  static CompleteInProcess = 24
  static ProcessCompleteFail = 25
  static PendingPayoutOnCancel = 26
  static CanceledByTheAdmin = 27
}

/**
 * @enum {string}
 * @see {@link https://github.com/RVezy/rvezy-back-end/blob/master/RVezy.Common/Models/BookingStageEnum.cs#L5}
 */
export class BookingStageEnumKey extends Enum {
  static AwaitingOwnerApproval = 'AwaitingOwnerApproval'
  static AwaitingPayment = 'AwaitingPayment'
  static DeclinedByTheOwner = 'DeclinedByTheOwner'
  static DeclinedByTheRenter = 'DeclinedByTheRenter'
  static DepositTaken = 'DepositTaken'
  static FullPaymentReceived = 'FullPaymentReceived'
  static SecurityDepositTaken = 'SecurityDepositTaken'
  static PendingPayout = 'PendingPayout'
  static Completed = 'Completed'
  static CanceledByTheRenter = 'CanceledByTheRenter'
  static CanceledByTheOwner = 'CanceledByTheOwner'
  static PendingInsuranceClaim = 'PendingInsuranceClaim'
  static ExpiredRequest = 'ExpiredRequest'
  static ExpiredNoPayment = 'ExpiredNoPayment'
  static EnquiryRequest = 'EnquiryRequest'
  static ExpiredByAdmin = 'ExpiredByAdmin'
  static TripStarted = 'TripStarted'
  static Invoice = 'Invoice'
  static InstantBooking = 'InstantBooking'
  static ExpiredInstantBook = 'ExpiredInstantBook'
  static CompleteInProcess = 'CompleteInProcess'
  static ProcessCompleteFail = 'ProcessCompleteFail'
  static PendingPayoutOnCancel = 'PendingPayoutOnCancel'
  static CanceledByTheAdmin = 'CanceledByTheAdmin'
}

/**
 * @enum {string}
 * @see {@link https://github.com/RVezy/rvezy-back-end/blob/master/RVezy.Common/CommonEnum/BookingTypeEnum.cs#L3}
 */
export class BookingTypeEnumKey extends Enum {
  static Normal = 'Normal'
  static Enquiry = 'Enquiry'
  static Invoice = 'Invoice'
  static Instant = 'Instant'
}

/**
 * @enum {string}
 * @see {@link https://github.com/RVezy/rvezy-back-end/blob/master/RVezy.Common/Models/Financials/CancellationType.cs#L9}
 */
export class CancellationTypeEnumKey extends Enum {
  static MoreThan30DaysBeforeRentalDate = 'MoreThan30DaysBeforeRentalDate'
  static Between30DaysAnd8DaysFromTheRentalDate = 'Between30DaysAnd8DaysFromTheRentalDate'
  static LessThanOrEqualTo7DaysFromTheRentalDate = 'LessThanOrEqualTo7DaysFromTheRentalDate'
  static Within24HourGracePeriod = 'Within24HourGracePeriod'
  static OwnerCancellation = 'OwnerCancellation'
  static CreditRefund = 'CreditRefund'
  static AdminCancellation = 'AdminCancellation'
  static Within30DaysFromFirstPayment = 'Within30DaysFromFirstPayment'
}

/**
 * @enum {number}
 * @see {@link https://github.com/RVezy/rvezy-back-end/blob/master/RVezy.Common/Models/Language.cs#L5}
 */
export class CommonLanguageEnum extends Enum {
  static English = 0
  static French = 1
  static Any = 2
}

/**
 * @enum {string}
 * @see {@link https://github.com/RVezy/rvezy-back-end/blob/master/RVezy.Common/Models/Language.cs#L5}
 */
export class CommonLanguageEnumKey extends Enum {
  static English = 'English'
  static French = 'French'
  static Any = 'Any'
}

/**
 * @enum {string}
 * @see {@link https://github.com/RVezy/rvezy-back-end/blob/master/RVezy.Common/CommonEnum/StatusEnum.cs#L3}
 */
export class CommonStatusEnumKey extends Enum {
  static Active = 'Active'
  static InActive = 'InActive'
}

/**
 * @enum {number}
 * @see {@link https://github.com/RVezy/rvezy-back-end/blob/master/RVezy.Common/Models/Country.cs#L5}
 */
export class CountryEnum extends Enum {
  static CA = 1
  static US = 2
  static ROW = 3
}

/**
 * @enum {string}
 * @see {@link https://github.com/RVezy/rvezy-back-end/blob/master/RVezy.Common/Models/Country.cs#L5}
 */
export class CountryEnumKey extends Enum {
  static CA = 'CA'
  static US = 'US'
  static ROW = 'ROW'
}

/**
 * @enum {string}
 * @see {@link https://github.com/RVezy/rvezy-back-end/blob/master/RVezy.Common/Models/Currency.cs#L5}
 */
export class CurrencyEnumKey extends Enum {
  static CAD = 'CAD'
  static USD = 'USD'
}

/**
 * @enum {string}
 * @see {@link https://github.com/RVezy/rvezy-back-end/blob/master/RVezy.Common/Models/Zendesk/DriverStatusEnum.cs#L5}
 */
export class DriverStatusEnumKey extends Enum {
  static Approved = 'Approved'
  static Denied = 'Denied'
  static NotFilled = 'NotFilled'
}

/**
 * @enum {string}
 * @see {@link https://github.com/RVezy/rvezy-back-end/blob/master/RVezy.Common/CommonEnum/ExperimentType.cs#L3}
 */
export class ExperimentTypeEnumKey extends Enum {
  static Device = 'Device'
  static User = 'User'
}

/**
 * @enum {number}
 * @see {@link https://github.com/RVezy/rvezy-back-end/blob/master/RVezy.Common/CommonEnum/GoalsAnswerEnum.cs#L3}
 */
export class GoalsAnswerEnum extends Enum {
  static Never = 0
  static Rarely = 1
  static Sometimes = 2
  static Frequently = 3
  static Always = 4
}

/**
 * @enum {string}
 * @see {@link https://github.com/RVezy/rvezy-back-end/blob/master/RVezy.Common/CommonEnum/GoalsAnswerEnum.cs#L3}
 */
export class GoalsAnswerEnumKey extends Enum {
  static Never = 'Never'
  static Rarely = 'Rarely'
  static Sometimes = 'Sometimes'
  static Frequently = 'Frequently'
  static Always = 'Always'
}

/**
 * @enum {number}
 * @see {@link https://github.com/RVezy/rvezy-back-end/blob/master/RVezyListingServer/Helpers/PointOfInterestGrammarRules.cs#L5}
 */
export class GrammarBitsEnum extends Enum {
  static IsMasculine = 1 << 0
  static IsFeminine = 1 << 1
  static IsIsland = 1 << 2
  static IsPluralized = 1 << 3
}

/**
 * @enum {string}
 * @see {@link https://github.com/RVezy/rvezy-back-end/blob/master/RVezy.Common/CommonEnum/InsuranceProtectionLevelEnum.cs#L3}
 */
export class InsuranceProtectionLevelEnumKey extends Enum {
  static Basic = 'Basic'
  static Standard = 'Standard'
  static Premium = 'Premium'
  static Stationary = 'Stationary'
}

/**
 * @enum {string}
 * @see {@link https://github.com/RVezy/rvezy-back-end/blob/master/RVezy.Common/CommonEnum/MessageTemplateDefault.cs#L3}
 */
export class MessageTemplateDefaultEnumKey extends Enum {
  static IntroMessage = 'IntroMessage'
  static CheckinCheckout = 'CheckinCheckout'
  static RVRules = 'RVRules'
  static DayOfTrip = 'DayOfTrip'
  static PostTripReview = 'PostTripReview'
}

/**
 * @enum {number}
 * @see {@link https://github.com/RVezy/rvezy-back-end/blob/master/RVezy.Common/CommonEnum/Months.cs#L3}
 */
export class MonthsEnum extends Enum {
  static January = 1
  static February = 2
  static March = 3
  static April = 4
  static May = 5
  static June = 6
  static July = 7
  static August = 8
  static September = 9
  static October = 10
  static November = 11
  static December = 12
}

/**
 * @enum {string}
 * @see {@link https://github.com/RVezy/rvezy-back-end/blob/master/RVezy.Common/CommonEnum/Months.cs#L3}
 */
export class MonthsEnumKey extends Enum {
  static January = 'January'
  static February = 'February'
  static March = 'March'
  static April = 'April'
  static May = 'May'
  static June = 'June'
  static July = 'July'
  static August = 'August'
  static September = 'September'
  static October = 'October'
  static November = 'November'
  static December = 'December'
}

/**
 * @enum {string}
 * @see {@link https://github.com/RVezy/rvezy-back-end/blob/master/RVezy.Data/Listing/Enums/PointOfInterestAttributeType.cs#L3}
 */
export class PointOfInterestAttributeTypeEnumKey extends Enum {
  static AllStaysId = 'AllStaysId'
  static GooglePlaceId = 'GooglePlaceId'
  static StreetAddress = 'StreetAddress'
  static BookingLink = 'BookingLink'
  static PhoneNumber = 'PhoneNumber'
  static WebsiteLink = 'WebsiteLink'
  static EmailAddress = 'EmailAddress'
  static PostalCode = 'PostalCode'
  static Amps15Available = 'Amps15Available'
  static Amps20Available = 'Amps20Available'
  static Amps30Available = 'Amps30Available'
  static Amps50Available = 'Amps50Available'
  static MaxRVLength = 'MaxRVLength'
  static Rates = 'Rates'
  static ReservationsRules = 'ReservationsRules'
  static AcceptableRVTypes = 'AcceptableRVTypes'
  static NumberOfSites = 'NumberOfSites'
  static IsPetFriendly = 'IsPetFriendly'
  static AcceptsBigRigs = 'AcceptsBigRigs'
  static HasAgeRestrictions = 'HasAgeRestrictions'
  static BeachAvailable = 'BeachAvailable'
  static BoatLaunchAvailable = 'BoatLaunchAvailable'
  static CableTVAvailable = 'CableTVAvailable'
  static GrillsAvailable = 'GrillsAvailable'
  static DumpStationAvailable = 'DumpStationAvailable'
  static EletricHookupSitesAvailable = 'EletricHookupSitesAvailable'
  static FullHookupSitesAvailable = 'FullHookupSitesAvailable'
  static FirewoodAvailable = 'FirewoodAvailable'
  static FishingAvailable = 'FishingAvailable'
  static HikingAvailable = 'HikingAvailable'
  static InternetAvailable = 'InternetAvailable'
  static LaundryFacilitiesAvailable = 'LaundryFacilitiesAvailable'
  static PlaygroundAvailable = 'PlaygroundAvailable'
  static PoolAvailable = 'PoolAvailable'
  static PropaneAvailable = 'PropaneAvailable'
  static PullThrusSitesAvailable = 'PullThrusSitesAvailable'
  static HasRVStorageAvailable = 'HasRVStorageAvailable'
  static HasShowersAvailable = 'HasShowersAvailable'
  static GeneralStoreAvailable = 'GeneralStoreAvailable'
  static TablesAvailable = 'TablesAvailable'
  static TentsSitesAvailable = 'TentsSitesAvailable'
  static ToiletsAvailable = 'ToiletsAvailable'
  static TrailsAvailable = 'TrailsAvailable'
  static WaterAvailable = 'WaterAvailable'
  static ElectricWaterHookupSitesAvailable = 'ElectricWaterHookupSitesAvailable'
  static HubId = 'HubId'
  static Abbreviation = 'Abbreviation'
  static ScoreRanking = 'ScoreRanking'
  static HubScoreRanking = 'HubScoreRanking'
  static RegionType = 'RegionType'
  static SeasonStarts = 'SeasonStarts'
  static SeasonEnds = 'SeasonEnds'
  static Population = 'Population'
  static TotalHousing = 'TotalHousing'
  static OccupiedHousing = 'OccupiedHousing'
  static LandAreaKM2 = 'LandAreaKM2'
  static WaterAreaKM2 = 'WaterAreaKM2'
  static GrammarRules = 'GrammarRules'
  static CountryId = 'CountryId'
  static Spot2NiteId = 'Spot2NiteId'
  static Spot2NiteUrl = 'Spot2NiteUrl'
}

/**
 * @enum {string}
 * @see {@link https://github.com/RVezy/rvezy-back-end/blob/master/RVezy.Data/Listing/Enums/PointOfInterestBlockContentType.cs#L3}
 */
export class PointOfInterestBlockContentTypeEnumKey extends Enum {
  static MetaTitle = 'MetaTitle'
  static MetaDescription = 'MetaDescription'
  static H1Title = 'H1Title'
  static H2Title = 'H2Title'
  static PreRvResultsContent = 'PreRvResultsContent'
  static PostRvResultsContent = 'PostRvResultsContent'
}

/**
 * @enum {string}
 * @see {@link https://github.com/RVezy/rvezy-back-end/blob/master/RVezy.Data/Listing/Enums/PointOfInterestType.cs#L3}
 */
export class PointOfInterestTypeEnumKey extends Enum {
  static Country = 'Country'
  static Region = 'Region'
  static City = 'City'
  static Park = 'Park'
  static ThemePark = 'ThemePark'
  static Campground = 'Campground'
  static SuburbanArea = 'SuburbanArea'
  static Global = 'Global'
}

/**
 * @enum {string}
 * @see {@link https://github.com/RVezy/rvezy-back-end/blob/master/RVezy.Common/CommonEnum/PreferenceEnum.cs#L3}
 */
export class PreferenceEnumKey extends Enum {
  static No = 'No'
  static Yes = 'Yes'
  static Negotiable = 'Negotiable'
}

/**
 * @enum {string}
 * @see {@link https://github.com/RVezy/rvezy-back-end/blob/master/RVezy.Common/Models/RVType.cs#L5}
 */
export class RVTypeEnumKey extends Enum {
  static FifthWheel = 'FifthWheel'
  static TentTrailer = 'TentTrailer'
  static TravelTrailer = 'TravelTrailer'
  static VintageTrailer = 'VintageTrailer'
  static Hybrid = 'Hybrid'
  static ToyHauler = 'ToyHauler'
  static ClassA = 'ClassA'
  static ClassB = 'ClassB'
  static ClassC = 'ClassC'
  static VintageMotorHome = 'VintageMotorHome'
  static Trailer = 'Trailer'
  static MotorHome = 'MotorHome'
  static MicroTrailer = 'MicroTrailer'
  static TruckCamper = 'TruckCamper'
  static Campervan = 'Campervan'
  static RVCottage = 'RVCottage'
}

/**
 * @enum {string}
 * @see {@link https://github.com/RVezy/rvezy-back-end/blob/master/RVezy.Common/Models/RentalType.cs#L5}
 */
export class RentalTypeEnumKey extends Enum {
  static RVCottage = 'RVCottage'
  static TravelTrailer = 'TravelTrailer'
  static MotorHome = 'MotorHome'
}

/**
 * @enum {string}
 * @see {@link https://github.com/RVezy/rvezy-back-end/blob/master/RVezy.Common/Models/ReviewTypeEnum.cs#L9}
 */
export class ReviewTypeEnumKey extends Enum {
  static Regular = 'Regular'
  static Automatic = 'Automatic'
}

/**
 * @enum {string}
 * @see {@link https://github.com/RVezy/rvezy-back-end/blob/master/RVezy.Common/CommonEnum/RvUsageType.cs#L3}
 */
export class RvUsageTypeEnumKey extends Enum {
  static Pickup = 'Pickup'
  static Delivery = 'Delivery'
  static StationaryAfterDelivery = 'StationaryAfterDelivery'
}

/**
 * @enum {string}
 * @see {@link https://github.com/RVezy/rvezy-back-end/blob/master/RVezy.Common/Models/Financials/StripeIntentTypeCommon.cs#L3}
 */
export class StripeIntentTypeCommonEnumKey extends Enum {
  static PaymentIntent = 'PaymentIntent'
  static SetupIntent = 'SetupIntent'
}

/**
 * @enum {string}
 * @see {@link https://github.com/RVezy/rvezy-back-end/blob/master/RVezy.Common/Models/VerificationStatus.cs#L3}
 */
export class VerificationStatusEnumKey extends Enum {
  static NotStarted = 'NotStarted'
  static InProgress = 'InProgress'
  static Verified = 'Verified'
  static NeedsReview = 'NeedsReview'
  static Rejected = 'Rejected'
}

/**
 * Make all the enums immutable
 */
Object.freeze(BookingPreferenceRequiredTypeEnumKey)
Object.freeze(BookingPreferencesTypeEnumKey)
Object.freeze(BookingReviewCategoryTypeEnumKey)
Object.freeze(BookingStageEnum)
Object.freeze(BookingStageEnumKey)
Object.freeze(BookingTypeEnumKey)
Object.freeze(CancellationTypeEnumKey)
Object.freeze(CommonLanguageEnum)
Object.freeze(CommonLanguageEnumKey)
Object.freeze(CommonStatusEnumKey)
Object.freeze(CountryEnum)
Object.freeze(CountryEnumKey)
Object.freeze(CurrencyEnumKey)
Object.freeze(DriverStatusEnumKey)
Object.freeze(ExperimentTypeEnumKey)
Object.freeze(GoalsAnswerEnum)
Object.freeze(GoalsAnswerEnumKey)
Object.freeze(GrammarBitsEnum)
Object.freeze(InsuranceProtectionLevelEnumKey)
Object.freeze(MessageTemplateDefaultEnumKey)
Object.freeze(MonthsEnum)
Object.freeze(MonthsEnumKey)
Object.freeze(PointOfInterestAttributeTypeEnumKey)
Object.freeze(PointOfInterestBlockContentTypeEnumKey)
Object.freeze(PointOfInterestTypeEnumKey)
Object.freeze(PreferenceEnumKey)
Object.freeze(RVTypeEnumKey)
Object.freeze(RentalTypeEnumKey)
Object.freeze(ReviewTypeEnumKey)
Object.freeze(RvUsageTypeEnumKey)
Object.freeze(StripeIntentTypeCommonEnumKey)
Object.freeze(VerificationStatusEnumKey)
