import { toRefs } from '@vueuse/core'

import type { NuxtError } from '#app'
import type { BookingChangeRequestParams, BookingChangeRequestResponse, Nullable } from '~/types'

/**
 * Returns the Change Request object from the state with a bunch of helpers/checks.
 */
export default function useChangeRequest(newChangeRequestData?: Ref<Nullable<BookingChangeRequestResponse>>) {
  const changeRequestType = useChangeRequestType()
  const changeRequestParams = useChangeRequestParams()
  const changeRequestPending = useChangeRequestPending()
  const changeRequestError = useChangeRequestError()
  const changeRequestSuccess = useChangeRequestSuccess()
  const changeRequestData = useSelectedChangeRequestData()

  if (newChangeRequestData !== undefined) {
    watch(newChangeRequestData, () => {
      changeRequestData.value = newChangeRequestData.value
    }, {
      immediate: true,
    })
  }

  const { $captureError, $rentals } = useNuxtApp()
  async function getChangeRequestDraft(params: BookingChangeRequestParams) {
    if (changeRequestPending.value || !params) {
      return
    }

    try {
      changeRequestPending.value = true
      changeRequestError.value = null

      const data = await $rentals('/api/bookings/{bookingId}/change-request/draft', {
        method: 'PUT',
        path: {
          bookingId: params.BookingId,
        },
        body: params,
      })

      changeRequestData.value = data
    }
    catch (error) {
      $captureError(error)
      changeRequestError.value = createError(error as NuxtError)
    }
    finally {
      changeRequestPending.value = false
    }
  }

  async function saveChangeRequestProtection() {
    if (changeRequestPending.value || !changeRequestData.value?.BookingId) {
      return
    }

    changeRequestPending.value = true
    changeRequestSuccess.value = false
    changeRequestError.value = null

    try {
      await $rentals('/api/bookings/{bookingId}/change-request/protection', {
        method: 'POST',
        path: {
          bookingId: changeRequestData.value.BookingId,
        },
        body: {
          BookingId: changeRequestData.value.BookingId,
          ExpectedTotal: changeRequestData.value?.ExpectedTotal,
          NotifyOwner: true,
          IncludeRoadside: changeRequestData.value?.RoadSideInsurance,
          Insurance: changeRequestData.value?.InsuranceProtectionLevel ? changeRequestData.value.InsuranceProtectionLevel : undefined,
        },
      })

      changeRequestSuccess.value = true
      changeRequestData.value = null
    }
    catch (error) {
      $captureError(error)
      changeRequestError.value = createError(error as NuxtError)
    }
    finally {
      changeRequestPending.value = false
    }
  }

  const { userIsBookingOwner } = useBookingUsers()
  async function applyChangeRequest() {
    if (changeRequestPending.value || !changeRequestData.value?.BookingId) {
      return
    }

    changeRequestPending.value = true
    changeRequestSuccess.value = false
    changeRequestError.value = null

    try {
      const path = userIsBookingOwner.value ? '/api/bookings/{id}/owner-change-request' : '/api/bookings/{id}/renter-change-request'
      await $rentals(path, {
        method: 'POST',
        path: {
          id: changeRequestData.value.BookingId,
        },
        body: changeRequestData.value,
      })

      changeRequestSuccess.value = true
    }
    catch (error) {
      $captureError(error)
      changeRequestError.value = createError(error as NuxtError)
    }
    finally {
      changeRequestPending.value = false
    }
  }

  async function acceptChangeRequest() {
    if (changeRequestPending.value || !changeRequestData.value?.BookingId) {
      return
    }

    changeRequestPending.value = true
    changeRequestSuccess.value = false
    changeRequestError.value = null

    try {
      await $rentals('/api/bookings/{bookingId}/change-request/approve', {
        method: 'POST',
        path: {
          bookingId: changeRequestData.value.BookingId,
        },
      })

      changeRequestSuccess.value = true
    }
    catch (error) {
      $captureError(error)
      changeRequestError.value = createError(error as NuxtError)
    }
    finally {
      changeRequestPending.value = false
    }
  }

  async function declineChangeRequest(bookingId: number, message: string) {
    if (changeRequestPending.value || !bookingId) {
      return
    }

    changeRequestPending.value = true
    changeRequestSuccess.value = false
    changeRequestError.value = null

    try {
      await $rentals('/api/bookings/{bookingId}/change-request/reject', {
        method: 'POST',
        path: {
          bookingId,
        },
        headers: {
          'Content-Type': 'application/json',
        },
        body: `"${message}"`,
      })

      changeRequestSuccess.value = true
    }
    catch (error) {
      $captureError(error)
      changeRequestError.value = createError(error as NuxtError)
    }
    finally {
      changeRequestPending.value = false
    }
  }

  function resetChangeRequest() {
    changeRequestData.value = null
    changeRequestError.value = null
    changeRequestPending.value = false
    changeRequestSuccess.value = false
    changeRequestType.value = null
  }

  const { user } = useAuthentication()

  return {
    changeRequestParams,
    changeRequestData,
    changeRequestError,
    changeRequestPending,
    changeRequestSuccess,
    changeRequestType,

    hasChangeRequestData: computed(() => Boolean(changeRequestData.value)),

    ...toRefs(computed(() => getChangeRequest(changeRequestData.value))),
    ...toRefs(computed(() => getFinancial(changeRequestData.value?.Financial))),

    userIsChangeRequestCreator: computed(() => changeRequestData.value?.UserId === user.value?.Id),

    acceptChangeRequest,
    applyChangeRequest,
    declineChangeRequest,
    getChangeRequestDraft,
    saveChangeRequestProtection,
    resetChangeRequest,
  }
}
